@media (min-width: 576px) {
    :root {
        --moads-font-size: 106.25%;
    }
}

@media (min-width: 768px) {
    :root {
        --moads-font-size: 112.5%;
    }
}

@media (min-width: 1024px) {
    :root {
        --moads-font-size: 118.75%;
    }
}

@media (min-width: 1280px) {
    :root {
        --moads-font-size: 125%;
    }
}

@media (min-width: 1536px) {
    :root {
        --moads-font-size: 131.25%;
    }
}
