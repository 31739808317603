@font-face {
    font-family: 'Mona Sans';
    src:
        url('../../assets/font/Mona-Sans.woff2') format('woff2 supports variations'),
        url('../../assets/font/Mona-Sans.woff2') format('woff2-variations');
    font-weight: 200 900;
    font-stretch: 75% 125%;
}

@font-face {
    font-family: 'Clash Display';
    src:
        url('../../assets/font/ClashDisplay-Variable.woff2') format('woff2 supports variations'),
        url('../../assets/font/ClashDisplay-Variable.woff2') format('woff2-variations');
    font-weight: 200 900;
    font-stretch: 75% 125%;
}

// @import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&display=swap');

:root {
    --moads-font-family-emoji: 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    --moads-font-family-sans-serif: 'Mona Sans', system-ui, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, Helvetica,
        Arial, 'Helvetica Neue', sans-serif, var(--moads-font-family-emoji);
    --moads-font-family-monospace: 'Mona Sans', ui-monospace, SFMono-Regular, 'SF Mono', Menlo, Consolas,
        'Liberation Mono', monospace, var(--moads-font-family-emoji);
    --moads-font-family-heading: 'Clash Display', system-ui, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, Helvetica,
        Arial, 'Helvetica Neue', sans-serif, var(--moads-font-family-emoji);
    --moads-font-family: var(--moads-font-family-sans-serif);
    --moads-line-height: 1.5;
    --moads-font-weight: 400;
    --moads-font-size: 100%;
    --moads-text-underline-offset: 0.1rem;
    --moads-border-radius: 0.25rem;
    --moads-border-width: 0.0625rem;
    --moads-outline-width: 0.125rem;
    --moads-transition: 0.2s ease-in-out;
    --moads-spacing: 1rem;
    --moads-typography-spacing-vertical: 1rem;
    --moads-block-spacing-vertical: var(--moads-spacing);
    --moads-block-spacing-horizontal: var(--moads-spacing);
    --moads-form-element-spacing-vertical: 0.75rem;
    --moads-form-element-spacing-horizontal: 1rem;
    --moads-group-box-shadow: 0 0 0 rgba(0, 0, 0, 0);
    --moads-group-box-shadow-focus-with-button: 0 0 0 var(--moads-outline-width) var(--moads-primary-focus);
    --moads-group-box-shadow-focus-with-input: 0 0 0 0.0625rem var(--moads-form-element-border-color);
    --moads-modal-overlay-backdrop-filter: blur(0.375rem);
    --moads-nav-element-spacing-vertical: 1rem;
    --moads-nav-element-spacing-horizontal: 0.5rem;
    --moads-nav-link-spacing-vertical: 0.5rem;
    --moads-nav-link-spacing-horizontal: 0.5rem;
    --moads-nav-breadcrumb-divider: '>';
    --moads-icon-checkbox: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='rgb(255, 255, 255)' stroke-width='4' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='20 6 9 17 4 12'%3E%3C/polyline%3E%3C/svg%3E");
    --moads-icon-minus: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='rgb(255, 255, 255)' stroke-width='4' stroke-linecap='round' stroke-linejoin='round'%3E%3Cline x1='5' y1='12' x2='19' y2='12'%3E%3C/line%3E%3C/svg%3E");
    --moads-icon-chevron: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='rgb(136, 145, 164)' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
    --moads-icon-date: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='rgb(136, 145, 164)' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Crect x='3' y='4' width='18' height='18' rx='2' ry='2'%3E%3C/rect%3E%3Cline x1='16' y1='2' x2='16' y2='6'%3E%3C/line%3E%3Cline x1='8' y1='2' x2='8' y2='6'%3E%3C/line%3E%3Cline x1='3' y1='10' x2='21' y2='10'%3E%3C/line%3E%3C/svg%3E");
    --moads-icon-time: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='rgb(136, 145, 164)' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Ccircle cx='12' cy='12' r='10'%3E%3C/circle%3E%3Cpolyline points='12 6 12 12 16 14'%3E%3C/polyline%3E%3C/svg%3E");
    --moads-icon-search: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='rgb(136, 145, 164)' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'%3E%3Ccircle cx='11' cy='11' r='8'%3E%3C/circle%3E%3Cline x1='21' y1='21' x2='16.65' y2='16.65'%3E%3C/line%3E%3C/svg%3E");
    --moads-icon-close: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='rgb(136, 145, 164)' stroke-width='3' stroke-linecap='round' stroke-linejoin='round'%3E%3Cline x1='18' y1='6' x2='6' y2='18'%3E%3C/line%3E%3Cline x1='6' y1='6' x2='18' y2='18'%3E%3C/line%3E%3C/svg%3E");
    --moads-icon-loading: url("data:image/svg+xml,%3Csvg fill='none' height='24' width='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' %3E%3Cstyle%3E g %7B animation: rotate 2s linear infinite; transform-origin: center center; %7D circle %7B stroke-dasharray: 75,100; stroke-dashoffset: -5; animation: dash 1.5s ease-in-out infinite; stroke-linecap: round; %7D @keyframes rotate %7B 0%25 %7B transform: rotate(0deg); %7D 100%25 %7B transform: rotate(360deg); %7D %7D @keyframes dash %7B 0%25 %7B stroke-dasharray: 1,100; stroke-dashoffset: 0; %7D 50%25 %7B stroke-dasharray: 44.5,100; stroke-dashoffset: -17.5; %7D 100%25 %7B stroke-dasharray: 44.5,100; stroke-dashoffset: -62; %7D %7D %3C/style%3E%3Cg%3E%3Ccircle cx='12' cy='12' r='10' fill='none' stroke='rgb(136, 145, 164)' stroke-width='4' /%3E%3C/g%3E%3C/svg%3E");
}

:root:not([data-theme='dark']),
[data-theme='light'] {
    --moads-hero-gradient: url(#linear-gradient-light);
    --moads-logo-text: #030b1e;
    --moads-logo-primary: #fb8c15;
    --moads-logo-secondary: #4d69e2;
    --moads-brightness-filter: brightness(1);
    --moads-background-color: #fff;
    --moads-color: #373c44;
    --moads-text-selection-color: rgba(2, 154, 232, 0.25);
    --moads-muted-color: #646b79;
    --moads-muted-border-color: #e7eaf0;
    --moads-primary: #fb8c15;
    --moads-primary-background: #fb8c15;
    --moads-primary-border: var(--moads-primary-background);
    --moads-primary-underline: rgba(251, 140, 21, 0.8);
    --moads-primary-hover: #f6860f;
    --moads-primary-hover-background: #f6860f;
    --moads-primary-hover-border: var(--moads-primary-hover-background);
    --moads-primary-hover-underline: var(--moads-primary-hover);
    --moads-primary-focus: rgba(251, 140, 21, 0.8);
    --moads-primary-inverse: #fff;
    --moads-secondary: #5d6b89;
    --moads-secondary-background: #525f7a;
    --moads-secondary-border: var(--moads-secondary-background);
    --moads-secondary-underline: rgba(93, 107, 137, 0.5);
    --moads-secondary-hover: #48536b;
    --moads-secondary-hover-background: #48536b;
    --moads-secondary-hover-border: var(--moads-secondary-hover-background);
    --moads-secondary-hover-underline: var(--moads-secondary-hover);
    --moads-secondary-focus: rgba(93, 107, 137, 0.25);
    --moads-secondary-inverse: #fff;
    --moads-contrast: #181c25;
    --moads-contrast-background: #181c25;
    --moads-contrast-border: var(--moads-contrast-background);
    --moads-contrast-underline: rgba(24, 28, 37, 0.5);
    --moads-contrast-hover: #000;
    --moads-contrast-hover-background: #000;
    --moads-contrast-hover-border: var(--moads-contrast-hover-background);
    --moads-contrast-hover-underline: var(--moads-secondary-hover);
    --moads-contrast-focus: rgba(93, 107, 137, 0.25);
    --moads-contrast-inverse: #fff;
    --moads-box-shadow: 0.0145rem 0.029rem 0.174rem rgba(129, 145, 181, 0.01698),
        0.0335rem 0.067rem 0.402rem rgba(129, 145, 181, 0.024), 0.0625rem 0.125rem 0.75rem rgba(129, 145, 181, 0.03),
        0.1125rem 0.225rem 1.35rem rgba(129, 145, 181, 0.036), 0.2085rem 0.417rem 2.502rem rgba(129, 145, 181, 0.04302),
        0.5rem 1rem 6rem rgba(129, 145, 181, 0.06), 0 0 0 0.0625rem rgba(129, 145, 181, 0.015);
    --moads-h1-color: #2d3138;
    --moads-h2-color: #373c44;
    --moads-h3-color: #424751;
    --moads-h4-color: #4d535e;
    --moads-h5-color: #5c6370;
    --moads-h6-color: #646b79;
    --moads-mark-background-color: #fde7c0;
    --moads-mark-color: #0f1114;
    --moads-ins-color: #1d6a54;
    --moads-del-color: #883935;
    --moads-att-color: #f4a535;
    --moads-blockquote-border-color: var(--moads-muted-border-color);
    --moads-blockquote-footer-color: var(--moads-muted-color);
    --moads-button-box-shadow: 0 0 0 rgba(0, 0, 0, 0);
    --moads-button-hover-box-shadow: 0 0 0 rgba(0, 0, 0, 0);
    --moads-table-border-color: var(--moads-muted-border-color);
    --moads-table-row-stripped-background-color: rgba(111, 120, 135, 0.0375);
    --moads-code-background-color: #f3f5f7;
    --moads-code-color: #646b79;
    --moads-code-kbd-background-color: var(--moads-color);
    --moads-code-kbd-color: var(--moads-background-color);
    --moads-form-element-background-color: #fbfcfc;
    --moads-form-element-selected-background-color: #dfe3eb;
    --moads-form-element-border-color: #cfd5e2;
    --moads-form-element-color: #23262c;
    --moads-form-element-placeholder-color: var(--moads-muted-color);
    --moads-form-element-active-background-color: #fff;
    --moads-form-element-active-border-color: var(--moads-primary-border);
    --moads-form-element-focus-color: var(--moads-primary-border);
    --moads-form-element-disabled-opacity: 0.5;
    --moads-form-element-invalid-border-color: #b86a6b;
    --moads-form-element-invalid-active-border-color: #c84f48;
    --moads-form-element-invalid-focus-color: var(--moads-form-element-invalid-active-border-color);
    --moads-form-element-valid-border-color: #4c9b8a;
    --moads-form-element-valid-active-border-color: #279977;
    --moads-form-element-valid-focus-color: var(--moads-form-element-valid-active-border-color);
    --moads-switch-background-color: #bfc7d9;
    --moads-switch-checked-background-color: var(--moads-primary-background);
    --moads-switch-color: #fff;
    --moads-switch-thumb-box-shadow: 0 0 0 rgba(0, 0, 0, 0);
    --moads-range-border-color: #dfe3eb;
    --moads-range-active-border-color: #bfc7d9;
    --moads-range-thumb-border-color: var(--moads-background-color);
    --moads-range-thumb-color: var(--moads-secondary-background);
    --moads-range-thumb-active-color: var(--moads-primary-background);
    --moads-accordion-border-color: var(--moads-muted-border-color);
    --moads-accordion-active-summary-color: var(--moads-primary-hover);
    --moads-accordion-close-summary-color: var(--moads-color);
    --moads-accordion-open-summary-color: var(--moads-muted-color);
    --moads-card-background-color: #f3f5f7;
    --moads-card-overlay-bg-color: #eceff2;
    --moads-card-border-color: var(--moads-muted-border-color);
    --moads-card-box-shadow: var(--moads-box-shadow);
    --moads-card-sectioning-background-color: #fbfcfc;
    --moads-loading-spinner-opacity: 0.5;
    --moads-modal-overlay-background-color: rgba(232, 234, 237, 0.75);
    --moads-progress-background-color: #dfe3eb;
    --moads-progress-color: var(--moads-primary-background);
    --moads-tooltip-background-color: var(--moads-contrast-background);
    --moads-tooltip-color: var(--moads-contrast-inverse);
    --moads-svg-filter: brightness(0) saturate(100%) invert(17%) sepia(16%) saturate(467%) hue-rotate(179deg) brightness(90%) contrast(91%);
    --moads-icon-valid: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='rgb(76, 155, 138)' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='20 6 9 17 4 12'%3E%3C/polyline%3E%3C/svg%3E");
    --moads-icon-invalid: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='rgb(200, 79, 72)' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Ccircle cx='12' cy='12' r='10'%3E%3C/circle%3E%3Cline x1='12' y1='8' x2='12' y2='12'%3E%3C/line%3E%3Cline x1='12' y1='16' x2='12.01' y2='16'%3E%3C/line%3E%3C/svg%3E");
    color-scheme: light;
}

:root:not([data-theme='dark'])
    input:is([type='submit'], [type='button'], [type='reset'], [type='checkbox'], [type='radio'], [type='file']),
[data-theme='light']
    input:is([type='submit'], [type='button'], [type='reset'], [type='checkbox'], [type='radio'], [type='file']) {
    --moads-form-element-focus-color: var(--moads-primary-focus);
}

[data-theme='dark'] {
    --moads-hero-gradient: url(#linear-gradient-dark);
    --moads-logo-text: #f3f3f3;
    --moads-logo-primary: #fb8c15;
    --moads-logo-secondary: #4d69e2;
    --moads-brightness-filter: brightness(10);
    --moads-background-color: #13171f;
    --moads-color: #c2c7d0;
    --moads-text-selection-color: rgba(1, 170, 255, 0.1875);
    --moads-muted-color: #8792a4;
    --moads-muted-border-color: #202632;
    --moads-primary: #fb8c15;
    --moads-primary-background: #fb8c15;
    --moads-primary-border: var(--moads-primary-background);
    --moads-primary-underline: rgba(251, 140, 21, 0.8);
    --moads-primary-hover: #f6860f;
    --moads-primary-hover-background: #f6860f;
    --moads-primary-hover-border: var(--moads-primary-hover-background);
    --moads-primary-hover-underline: var(--moads-primary-hover);
    --moads-primary-focus: rgba(251, 140, 21, 0.8);
    --moads-primary-inverse: #fff;
    --moads-secondary: #969eaf;
    --moads-secondary-background: #525f7a;
    --moads-secondary-border: var(--moads-secondary-background);
    --moads-secondary-underline: rgba(150, 158, 175, 0.5);
    --moads-secondary-hover: #b3b9c5;
    --moads-secondary-hover-background: #5d6b89;
    --moads-secondary-hover-border: var(--moads-secondary-hover-background);
    --moads-secondary-hover-underline: var(--moads-secondary-hover);
    --moads-secondary-focus: rgba(144, 158, 190, 0.25);
    --moads-secondary-inverse: #fff;
    --moads-contrast: #dfe3eb;
    --moads-contrast-background: #eff1f4;
    --moads-contrast-border: var(--moads-contrast-background);
    --moads-contrast-underline: rgba(223, 227, 235, 0.5);
    --moads-contrast-hover: #fff;
    --moads-contrast-hover-background: #fff;
    --moads-contrast-hover-border: var(--moads-contrast-hover-background);
    --moads-contrast-hover-underline: var(--moads-contrast-hover);
    --moads-contrast-focus: rgba(207, 213, 226, 0.25);
    --moads-contrast-inverse: #000;
    --moads-box-shadow: 0.0145rem 0.029rem 0.174rem rgba(7, 9, 12, 0.01698),
        0.0335rem 0.067rem 0.402rem rgba(7, 9, 12, 0.024), 0.0625rem 0.125rem 0.75rem rgba(7, 9, 12, 0.03),
        0.1125rem 0.225rem 1.35rem rgba(7, 9, 12, 0.036), 0.2085rem 0.417rem 2.502rem rgba(7, 9, 12, 0.04302),
        0.5rem 1rem 6rem rgba(7, 9, 12, 0.06), 0 0 0 0.0625rem rgba(7, 9, 12, 0.015);
    --moads-h1-color: #f0f1f3;
    --moads-h2-color: #e0e3e7;
    --moads-h3-color: #c2c7d0;
    --moads-h4-color: #b3b9c5;
    --moads-h5-color: #a4acba;
    --moads-h6-color: #8891a4;
    --moads-mark-background-color: #014063;
    --moads-mark-color: #fff;
    --moads-ins-color: #62af9a;
    --moads-del-color: #ce7e7b;
    --moads-att-color: #ce9d7b;
    --moads-blockquote-border-color: var(--moads-muted-border-color);
    --moads-blockquote-footer-color: var(--moads-muted-color);
    --moads-button-box-shadow: 0 0 0 rgba(0, 0, 0, 0);
    --moads-button-hover-box-shadow: 0 0 0 rgba(0, 0, 0, 0);
    --moads-table-border-color: var(--moads-muted-border-color);
    --moads-table-row-stripped-background-color: rgba(111, 120, 135, 0.0375);
    --moads-code-background-color: #1a1f28;
    --moads-code-color: #8891a4;
    --moads-code-kbd-background-color: var(--moads-color);
    --moads-code-kbd-color: var(--moads-background-color);
    --moads-form-element-background-color: #1c212c;
    --moads-form-element-selected-background-color: #2a3140;
    --moads-form-element-border-color: #2a3140;
    --moads-form-element-color: #e0e3e7;
    --moads-form-element-placeholder-color: #8891a4;
    --moads-form-element-active-background-color: #1a1f28;
    --moads-form-element-active-border-color: var(--moads-primary-border);
    --moads-form-element-focus-color: var(--moads-primary-border);
    --moads-form-element-disabled-opacity: 0.5;
    --moads-form-element-invalid-border-color: #964a50;
    --moads-form-element-invalid-active-border-color: #b7403b;
    --moads-form-element-invalid-focus-color: var(--moads-form-element-invalid-active-border-color);
    --moads-form-element-valid-border-color: #2a7b6f;
    --moads-form-element-valid-active-border-color: #16896a;
    --moads-form-element-valid-focus-color: var(--moads-form-element-valid-active-border-color);
    --moads-switch-background-color: #333c4e;
    --moads-switch-checked-background-color: var(--moads-primary-background);
    --moads-switch-color: #fff;
    --moads-switch-thumb-box-shadow: 0 0 0 rgba(0, 0, 0, 0);
    --moads-range-border-color: #202632;
    --moads-range-active-border-color: #2a3140;
    --moads-range-thumb-border-color: var(--moads-background-color);
    --moads-range-thumb-color: var(--moads-secondary-background);
    --moads-range-thumb-active-color: var(--moads-primary-background);
    --moads-accordion-border-color: var(--moads-muted-border-color);
    --moads-accordion-active-summary-color: var(--moads-primary-hover);
    --moads-accordion-close-summary-color: var(--moads-color);
    --moads-accordion-open-summary-color: var(--moads-muted-color);
    --moads-card-background-color: #181c25;
    --moads-card-overlay-bg-color: rgba(0, 0, 0, 0.15);
    --moads-card-border-color: var(--moads-card-background-color);
    --moads-card-box-shadow: var(--moads-box-shadow);
    --moads-card-sectioning-background-color: #1a1f28;
    --moads-loading-spinner-opacity: 0.5;
    --moads-modal-overlay-background-color: rgba(8, 9, 10, 0.75);
    --moads-progress-background-color: #202632;
    --moads-progress-color: var(--moads-primary-background);
    --moads-tooltip-background-color: var(--moads-contrast-background);
    --moads-tooltip-color: var(--moads-contrast-inverse);
    --moads-svg-filter: none;
    --moads-icon-valid: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='rgb(42, 123, 111)' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='20 6 9 17 4 12'%3E%3C/polyline%3E%3C/svg%3E");
    --moads-icon-invalid: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='rgb(150, 74, 80)' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Ccircle cx='12' cy='12' r='10'%3E%3C/circle%3E%3Cline x1='12' y1='8' x2='12' y2='12'%3E%3C/line%3E%3Cline x1='12' y1='16' x2='12.01' y2='16'%3E%3C/line%3E%3C/svg%3E");
    color-scheme: dark;
}

[data-theme='dark']
    input:is([type='submit'], [type='button'], [type='reset'], [type='checkbox'], [type='radio'], [type='file']) {
    --moads-form-element-focus-color: var(--moads-primary-focus);
}

@media only screen and (prefers-color-scheme: dark) {
    :root:not([data-theme]) {
        --moads-hero-gradient: url(#linear-gradient-dark);
        --moads-logo-text: #f3f3f3;
        --moads-brightness-filter: brightness(10);
        --moads-background-color: #13171f;
        --moads-color: #c2c7d0;
        --moads-text-selection-color: rgba(1, 170, 255, 0.1875);
        --moads-muted-color: #8792a4;
        --moads-muted-border-color: #202632;
        --moads-primary: #01aaff;
        --moads-primary-background: #0172ad;
        --moads-primary-border: var(--moads-primary-background);
        --moads-primary-underline: rgba(1, 170, 255, 0.5);
        --moads-primary-hover: #79c0ff;
        --moads-primary-hover-background: #017fc0;
        --moads-primary-hover-border: var(--moads-primary-hover-background);
        --moads-primary-hover-underline: var(--moads-primary-hover);
        --moads-primary-focus: rgba(1, 170, 255, 0.375);
        --moads-primary-inverse: #fff;
        --moads-secondary: #969eaf;
        --moads-secondary-background: #525f7a;
        --moads-secondary-border: var(--moads-secondary-background);
        --moads-secondary-underline: rgba(150, 158, 175, 0.5);
        --moads-secondary-hover: #b3b9c5;
        --moads-secondary-hover-background: #5d6b89;
        --moads-secondary-hover-border: var(--moads-secondary-hover-background);
        --moads-secondary-hover-underline: var(--moads-secondary-hover);
        --moads-secondary-focus: rgba(144, 158, 190, 0.25);
        --moads-secondary-inverse: #fff;
        --moads-contrast: #dfe3eb;
        --moads-contrast-background: #eff1f4;
        --moads-contrast-border: var(--moads-contrast-background);
        --moads-contrast-underline: rgba(223, 227, 235, 0.5);
        --moads-contrast-hover: #fff;
        --moads-contrast-hover-background: #fff;
        --moads-contrast-hover-border: var(--moads-contrast-hover-background);
        --moads-contrast-hover-underline: var(--moads-contrast-hover);
        --moads-contrast-focus: rgba(207, 213, 226, 0.25);
        --moads-contrast-inverse: #000;
        --moads-box-shadow: 0.0145rem 0.029rem 0.174rem rgba(7, 9, 12, 0.01698),
            0.0335rem 0.067rem 0.402rem rgba(7, 9, 12, 0.024), 0.0625rem 0.125rem 0.75rem rgba(7, 9, 12, 0.03),
            0.1125rem 0.225rem 1.35rem rgba(7, 9, 12, 0.036), 0.2085rem 0.417rem 2.502rem rgba(7, 9, 12, 0.04302),
            0.5rem 1rem 6rem rgba(7, 9, 12, 0.06), 0 0 0 0.0625rem rgba(7, 9, 12, 0.015);
        --moads-h1-color: #f0f1f3;
        --moads-h2-color: #e0e3e7;
        --moads-h3-color: #c2c7d0;
        --moads-h4-color: #b3b9c5;
        --moads-h5-color: #a4acba;
        --moads-h6-color: #8891a4;
        --moads-mark-background-color: #014063;
        --moads-mark-color: #fff;
        --moads-ins-color: #62af9a;
        --moads-del-color: #ce7e7b;
        --moads-att-color: #ce9d7b;
        --moads-blockquote-border-color: var(--moads-muted-border-color);
        --moads-blockquote-footer-color: var(--moads-muted-color);
        --moads-button-box-shadow: 0 0 0 rgba(0, 0, 0, 0);
        --moads-button-hover-box-shadow: 0 0 0 rgba(0, 0, 0, 0);
        --moads-table-border-color: var(--moads-muted-border-color);
        --moads-table-row-stripped-background-color: rgba(111, 120, 135, 0.0375);
        --moads-code-background-color: #1a1f28;
        --moads-code-color: #8891a4;
        --moads-code-kbd-background-color: var(--moads-color);
        --moads-code-kbd-color: var(--moads-background-color);
        --moads-form-element-background-color: #1c212c;
        --moads-form-element-selected-background-color: #2a3140;
        --moads-form-element-border-color: #2a3140;
        --moads-form-element-color: #e0e3e7;
        --moads-form-element-placeholder-color: #8891a4;
        --moads-form-element-active-background-color: #1a1f28;
        --moads-form-element-active-border-color: var(--moads-primary-border);
        --moads-form-element-focus-color: var(--moads-primary-border);
        --moads-form-element-disabled-opacity: 0.5;
        --moads-form-element-invalid-border-color: #964a50;
        --moads-form-element-invalid-active-border-color: #b7403b;
        --moads-form-element-invalid-focus-color: var(--moads-form-element-invalid-active-border-color);
        --moads-form-element-valid-border-color: #2a7b6f;
        --moads-form-element-valid-active-border-color: #16896a;
        --moads-form-element-valid-focus-color: var(--moads-form-element-valid-active-border-color);
        --moads-switch-background-color: #333c4e;
        --moads-switch-checked-background-color: var(--moads-primary-background);
        --moads-switch-color: #fff;
        --moads-switch-thumb-box-shadow: 0 0 0 rgba(0, 0, 0, 0);
        --moads-range-border-color: #202632;
        --moads-range-active-border-color: #2a3140;
        --moads-range-thumb-border-color: var(--moads-background-color);
        --moads-range-thumb-color: var(--moads-secondary-background);
        --moads-range-thumb-active-color: var(--moads-primary-background);
        --moads-accordion-border-color: var(--moads-muted-border-color);
        --moads-accordion-active-summary-color: var(--moads-primary-hover);
        --moads-accordion-close-summary-color: var(--moads-color);
        --moads-accordion-open-summary-color: var(--moads-muted-color);
        --moads-card-background-color: #181c25;
        --moads-card-border-color: var(--moads-card-background-color);
        --moads-card-box-shadow: var(--moads-box-shadow);
        --moads-card-sectioning-background-color: #1a1f28;
        --moads-loading-spinner-opacity: 0.5;
        --moads-modal-overlay-background-color: rgba(8, 9, 10, 0.75);
        --moads-progress-background-color: #202632;
        --moads-progress-color: var(--moads-primary-background);
        --moads-tooltip-background-color: var(--moads-contrast-background);
        --moads-tooltip-color: var(--moads-contrast-inverse);
        --moads-svg-filter: brightness(0) saturate(100%) invert(17%) sepia(16%) saturate(467%) hue-rotate(179deg) brightness(90%) contrast(91%);
        --moads-icon-valid: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='rgb(42, 123, 111)' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='20 6 9 17 4 12'%3E%3C/polyline%3E%3C/svg%3E");
        --moads-icon-invalid: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='rgb(150, 74, 80)' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Ccircle cx='12' cy='12' r='10'%3E%3C/circle%3E%3Cline x1='12' y1='8' x2='12' y2='12'%3E%3C/line%3E%3Cline x1='12' y1='16' x2='12.01' y2='16'%3E%3C/line%3E%3C/svg%3E");
        color-scheme: dark;
    }

    :root:not([data-theme])
        input:is([type='submit'], [type='button'], [type='reset'], [type='checkbox'], [type='radio'], [type='file']) {
        --moads-form-element-focus-color: var(--moads-primary-focus);
    }
}
