@charset "UTF-8";

a {
    --moads-text-decoration: none;
}

small {
    --moads-font-size: 0.875em;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    --moads-font-weight: 700;
}

h1 {
    --moads-font-size: 2.986rem;
    --moads-line-height: 1.6;
    --moads-typography-spacing-top: 3rem;
}

h2 {
    --moads-font-size: 2.074rem;
    --moads-line-height: 1.14;
    --moads-typography-spacing-top: 2.625rem;
}

h3 {
    --moads-font-size: 1.62rem;
    --moads-line-height: 1.175;
    --moads-typography-spacing-top: 2.25rem;
}

h4 {
    --moads-font-size: 1.44rem;
    --moads-line-height: 1.2;
    --moads-typography-spacing-top: 1.874rem;
}

h5 {
    --moads-font-size: 1.2rem;
    --moads-line-height: 1.225;
    --moads-typography-spacing-top: 1.6875rem;
}

h6 {
    --moads-font-size: 1rem;
    --moads-line-height: 1.5;
    --moads-typography-spacing-top: 1.5rem;
}

tfoot td,
tfoot th,
thead td,
thead th {
    --moads-font-weight: 600;
    --moads-border-width: 0.1875rem;
}

code,
kbd,
pre,
samp {
    --moads-font-family: var(--moads-font-family-monospace);
}

kbd {
    --moads-font-weight: bolder;
}

:where(select, textarea),
input:not([type='submit'], [type='button'], [type='reset'], [type='checkbox'], [type='radio'], [type='file']) {
    --moads-outline-width: 0.0625rem;
}

[type='search'] {
    --moads-border-radius: 5rem;
}

[type='checkbox'],
[type='radio'] {
    --moads-border-width: 0.125rem;
}

[type='checkbox'][role='switch'] {
    --moads-border-width: 0.1875rem;
}

[role='search'] {
    --moads-border-radius: 5rem;
}

[role='group'] [role='button']:not(.ccm-must-show, .button-1),
[role='group'] [type='button'],
[role='group'] [type='submit'],
[role='group'] button,
[role='search'] [role='button'],
[role='search'] [type='button'],
[role='search'] [type='submit'],
[role='search'] button {
    --moads-form-element-spacing-horizontal: 2rem;
}

details summary[role='button']::after {
    filter: brightness(0) invert(1);
}

[aria-busy='true']:not(input, select, textarea):is(
        button,
        [type='submit'],
        [type='button'],
        [type='reset'],
        [role='button']
    )::before {
    filter: brightness(0) invert(1);
}

[type='checkbox'],
[type='radio'],
[type='range'],
progress {
    accent-color: var(--moads-primary);
}

*,
::after,
::before {
    box-sizing: border-box;
    background-repeat: no-repeat;
}

::after,
::before {
    text-decoration: inherit;
    vertical-align: inherit;
}

:where(:root) {
    -webkit-tap-highlight-color: transparent;
    -webkit-text-size-adjust: 100%;
    -moz-text-size-adjust: 100%;
    text-size-adjust: 100%;
    background-color: var(--moads-background-color);
    color: var(--moads-color);
    font-weight: var(--moads-font-weight);
    font-size: var(--moads-font-size);
    line-height: var(--moads-line-height);
    font-family: var(--moads-font-family);
    text-underline-offset: var(--moads-text-underline-offset);
    text-rendering: optimizeLegibility;
    overflow-wrap: break-word;
    -moz-tab-size: 4;
    -o-tab-size: 4;
    tab-size: 4;
}

body {
    width: 100%;
    margin: 0;
    overflow-x: hidden;
}

main {
    display: block;
}

footer,
header,
main,
.container,
.container-mini {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    padding: var(--moads-block-spacing-vertical) var(--moads-block-spacing-horizontal);
}

@media (min-width: 576px) {
    footer,
    header,
    main,
    .container,
    .container-mini {
        max-width: 510px;
        padding-right: 0;
        padding-left: 0;
    }
}

@media (min-width: 768px) {
    footer,
    header,
    main,
    .container {
        max-width: 700px;
    }

    .container-mini {
        max-width: 640px;
    }
}

@media (min-width: 1024px) {
    footer,
    header,
    main,
    .container {
        max-width: 950px;
    }
}

@media (min-width: 1280px) {
    footer,
    header,
    main,
    .container {
        max-width: 1200px;
    }
}

@media (min-width: 1536px) {
    footer,
    header,
    main,
    .container {
        max-width: 1200px;
    }
}

section {
    position: relative;
    padding-block-start: 7rem;
    padding-block-end: 3rem;
    margin-bottom: var(--moads-block-spacing-vertical);
}

b,
strong {
    font-weight: bolder;
}

sub,
sup {
    position: relative;
    font-size: 0.75em;
    line-height: 0;
    vertical-align: baseline;
}

sub {
    bottom: -0.25em;
}

sup {
    top: -0.5em;
}

address,
blockquote,
dl,
ol,
p,
pre,
table,
ul {
    margin-top: 0;
    margin-bottom: var(--moads-typography-spacing-vertical);
    color: var(--moads-color);
    font-style: normal;
    font-weight: var(--moads-font-weight);
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin-top: 0;
    margin-bottom: var(--moads-typography-spacing-vertical);
    color: var(--moads-color);
    font-weight: var(--moads-font-weight);
    font-size: var(--moads-font-size);
    line-height: var(--moads-line-height);
    font-family: var(--moads-font-family);
}

h1 {
    --moads-color: var(--moads-h1-color);
}

h2 {
    --moads-color: var(--moads-h2-color);
}

h3 {
    --moads-color: var(--moads-h3-color);
}

h4 {
    --moads-color: var(--moads-h4-color);
}

h5 {
    --moads-color: var(--moads-h5-color);
}

h6 {
    --moads-color: var(--moads-h6-color);
}

:where(article, address, blockquote, dl, figure, form, ol, p, pre, table, ul) ~ :is(h1, h2, h3, h4, h5, h6) {
    margin-top: var(--moads-typography-spacing-top);
}

p {
    margin-bottom: var(--moads-typography-spacing-vertical);
}

hgroup {
    margin-bottom: var(--moads-typography-spacing-vertical);
}

hgroup > * {
    margin-top: 0;
    margin-bottom: 0;
}

hgroup > :not(:first-child):last-child {
    --moads-color: var(--moads-muted-color);
    --moads-font-weight: unset;
    font-size: 1rem;
}

:where(ol, ul) li {
    margin-bottom: calc(var(--moads-typography-spacing-vertical) * 0.25);
}

:where(dl, ol, ul) :where(dl, ol, ul) {
    margin: 0;
    margin-top: calc(var(--moads-typography-spacing-vertical) * 0.25);
}

ul li {
    list-style: square;
}

mark {
    padding: 0.125rem 0.25rem;
    background-color: var(--moads-mark-background-color);
    color: var(--moads-mark-color);
    vertical-align: baseline;
}

blockquote {
    display: block;
    margin: var(--moads-typography-spacing-vertical) 0;
    padding: var(--moads-spacing);
    border-right: none;
    border-left: 0.25rem solid var(--moads-blockquote-border-color);
    border-inline-start: 0.25rem solid var(--moads-blockquote-border-color);
    border-inline-end: none;
}

blockquote footer {
    margin-top: calc(var(--moads-typography-spacing-vertical) * 0.5);
    color: var(--moads-blockquote-footer-color);
}

abbr[title] {
    border-bottom: 1px dotted;
    text-decoration: none;
    cursor: help;
}

ins {
    color: var(--moads-ins-color);
    text-decoration: none;
}

del {
    color: var(--moads-del-color);
}

::-moz-selection {
    background-color: var(--moads-text-selection-color);
}

::selection {
    background-color: var(--moads-text-selection-color);
}

:where(a:not([role='button'])),
[role='link'] {
    --moads-color: var(--moads-primary);
    --moads-background-color: transparent;
    --moads-underline: var(--moads-primary-underline);
    outline: 0;
    background-color: var(--moads-background-color);
    color: var(--moads-color);
    -webkit-text-decoration: var(--moads-text-decoration);
    text-decoration: var(--moads-text-decoration);
    text-decoration-color: var(--moads-underline);
    text-underline-offset: 0.125em;
    transition:
        background-color var(--moads-transition),
        color var(--moads-transition),
        box-shadow var(--moads-transition),
        -webkit-text-decoration var(--moads-transition);
    transition:
        background-color var(--moads-transition),
        color var(--moads-transition),
        text-decoration var(--moads-transition),
        box-shadow var(--moads-transition);
    transition:
        background-color var(--moads-transition),
        color var(--moads-transition),
        text-decoration var(--moads-transition),
        box-shadow var(--moads-transition),
        -webkit-text-decoration var(--moads-transition);
}

:where(a:not([role='button'])):is([aria-current]:not([aria-current='false']), :hover, :active, :focus),
[role='link']:is([aria-current]:not([aria-current='false']), :hover, :active, :focus) {
    --moads-color: var(--moads-primary-hover);
    --moads-underline: var(--moads-primary-hover-underline);
    --moads-text-decoration: underline;
}

:where(a:not([role='button'])):focus-visible,
[role='link']:focus-visible {
    box-shadow: 0 0 0 var(--moads-outline-width) var(--moads-primary-focus);
}

a[role='button'] {
    display: inline-block;
}

button {
    margin: 0;
    overflow: visible;
    font-family: inherit;
    text-transform: none;
}

[type='button'],
[type='reset'],
[type='submit'],
button {
    -webkit-appearance: button;
}

[role='button']:not(.ccm-must-show, .button-1),
[type='button'],
[type='file']::file-selector-button,
[type='reset'],
[type='submit'],
button {
    --moads-background-color: var(--moads-primary-background);
    --moads-border-color: var(--moads-primary-border);
    --moads-color: var(--moads-primary-inverse);
    --moads-box-shadow: var(--moads-button-box-shadow, 0 0 0 rgba(0, 0, 0, 0));
    padding: var(--moads-form-element-spacing-vertical) var(--moads-form-element-spacing-horizontal);
    border: var(--moads-border-width) solid var(--moads-border-color);
    border-radius: var(--moads-border-radius);
    outline: 0;
    background-color: var(--moads-background-color);
    box-shadow: var(--moads-box-shadow);
    color: var(--moads-color);
    font-weight: var(--moads-font-weight);
    font-size: 1rem;
    line-height: var(--moads-line-height);
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    transition:
        background-color var(--moads-transition),
        border-color var(--moads-transition),
        color var(--moads-transition),
        box-shadow var(--moads-transition);
}

[role='button']:is(:hover, :active, :focus),
[role='button']:is([aria-current]:not([aria-current='false'])),
[type='button']:is(:hover, :active, :focus),
[type='button']:is([aria-current]:not([aria-current='false'])),
[type='file']::file-selector-button:is(:hover, :active, :focus),
[type='file']::file-selector-button:is([aria-current]:not([aria-current='false'])),
[type='reset']:is(:hover, :active, :focus),
[type='reset']:is([aria-current]:not([aria-current='false'])),
[type='submit']:is(:hover, :active, :focus),
[type='submit']:is([aria-current]:not([aria-current='false'])),
button:is(:hover, :active, :focus),
button:is([aria-current]:not([aria-current='false'])) {
    --moads-background-color: var(--moads-primary-hover-background);
    --moads-border-color: var(--moads-primary-hover-border);
    --moads-box-shadow: var(--moads-button-hover-box-shadow, 0 0 0 rgba(0, 0, 0, 0));
    --moads-color: var(--moads-primary-inverse);
}

[role='button']:focus,
[role='button']:is([aria-current]:not([aria-current='false'])):focus,
[type='button']:focus,
[type='button']:is([aria-current]:not([aria-current='false'])):focus,
[type='file']::file-selector-button:focus,
[type='file']::file-selector-button:is([aria-current]:not([aria-current='false'])):focus,
[type='reset']:focus,
[type='reset']:is([aria-current]:not([aria-current='false'])):focus,
[type='submit']:focus,
[type='submit']:is([aria-current]:not([aria-current='false'])):focus,
button:focus,
button:is([aria-current]:not([aria-current='false'])):focus {
    --moads-box-shadow: var(--moads-button-hover-box-shadow, 0 0 0 rgba(0, 0, 0, 0)),
        0 0 0 var(--moads-outline-width) var(--moads-primary-focus);
}

[type='button'],
[type='reset'],
[type='submit'] {
    margin-bottom: var(--moads-spacing);
}

[type='file']::file-selector-button,
[type='reset'] {
    --moads-background-color: var(--moads-secondary-background);
    --moads-border-color: var(--moads-secondary-border);
    --moads-color: var(--moads-secondary-inverse);
    cursor: pointer;
}

[type='file']::file-selector-button:is([aria-current]:not([aria-current='false']), :hover, :active, :focus),
[type='reset']:is([aria-current]:not([aria-current='false']), :hover, :active, :focus) {
    --moads-background-color: var(--moads-secondary-hover-background);
    --moads-border-color: var(--moads-secondary-hover-border);
    --moads-color: var(--moads-secondary-inverse);
}

[type='file']::file-selector-button:focus,
[type='reset']:focus {
    --moads-box-shadow: var(--moads-button-hover-box-shadow, 0 0 0 rgba(0, 0, 0, 0)),
        0 0 0 var(--moads-outline-width) var(--moads-secondary-focus);
}

:where(button, [type='submit'], [type='reset'], [type='button'], [role='button'])[disabled],
:where(fieldset[disabled]) :is(button, [type='submit'], [type='button'], [type='reset'], [role='button']) {
    opacity: 0.5;
    pointer-events: none;
}

:where(table) {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    text-indent: 0;
}

td,
th {
    padding: calc(var(--moads-spacing) / 2) var(--moads-spacing);
    border-bottom: var(--moads-border-width) solid var(--moads-table-border-color);
    background-color: var(--moads-background-color);
    color: var(--moads-color);
    font-weight: var(--moads-font-weight);
    text-align: left;
    text-align: start;
}

tfoot td,
tfoot th {
    border-top: var(--moads-border-width) solid var(--moads-table-border-color);
    border-bottom: 0;
}

table.striped tbody tr:nth-child(odd) td,
table.striped tbody tr:nth-child(odd) th {
    background-color: var(--moads-table-row-stripped-background-color);
}

:where(audio, canvas, iframe, img, svg, video) {
    vertical-align: middle;
}

audio,
video {
    display: inline-block;
}

audio:not([controls]) {
    display: none;
    height: 0;
}

:where(iframe) {
    border-style: none;
}

img {
    max-width: 100%;
    height: auto;
    border-style: none;
}

:where(svg:not([fill])) {
    fill: currentColor;
}

svg:not(:root) {
    overflow: hidden;
}

code,
kbd,
pre,
samp {
    font-size: 0.875em;
    font-family: var(--moads-font-family);
}

pre code {
    font-size: inherit;
    font-family: inherit;
}

pre {
    -ms-overflow-style: scrollbar;
    overflow: auto;
}

code,
kbd,
pre {
    border-radius: var(--moads-border-radius);
    background: var(--moads-code-background-color);
    color: var(--moads-code-color);
    font-weight: var(--moads-font-weight);
    line-height: initial;
}

code,
kbd {
    display: inline-block;
    padding: 0.375rem;
}

pre {
    display: block;
    margin-bottom: var(--moads-spacing);
    overflow-x: auto;
}

pre > code {
    display: block;
    padding: var(--moads-spacing);
    background: 0 0;
    line-height: var(--moads-line-height);
}

kbd {
    background-color: var(--moads-code-kbd-background-color);
    color: var(--moads-code-kbd-color);
    vertical-align: baseline;
}

figure {
    display: block;
    margin: 0;
    padding: 0;
}

figure figcaption {
    padding: calc(var(--moads-spacing) * 0.5) 0;
    color: var(--moads-muted-color);
}

hr {
    height: 0;
    margin: var(--moads-typography-spacing-vertical) 0;
    border: 0;
    color: inherit;
}

[hidden],
template {
    display: none !important;
}

canvas {
    display: inline-block;
}

input,
optgroup,
select,
textarea {
    margin: 0;
    font-size: 1rem;
    line-height: var(--moads-line-height);
    font-family: inherit;
    letter-spacing: inherit;
}

input {
    overflow: visible;
}

select {
    text-transform: none;
}

legend {
    max-width: 100%;
    padding: 0;
    color: inherit;
    white-space: normal;
}

textarea {
    overflow: auto;
}

[type='checkbox'],
[type='radio'] {
    padding: 0;
}

::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
    height: auto;
}

[type='search'] {
    -webkit-appearance: textfield;
    outline-offset: -2px;
}

[type='search']::-webkit-search-decoration {
    -webkit-appearance: none;
}

::-webkit-file-upload-button {
    -webkit-appearance: button;
    font: inherit;
}

::-moz-focus-inner {
    padding: 0;
    border-style: none;
}

:-moz-focusring {
    outline: 0;
}

:-moz-ui-invalid {
    box-shadow: none;
}

::-ms-expand {
    display: none;
}

[type='file'],
[type='range'] {
    padding: 0;
    border-width: 0;
}

input:not([type='checkbox'], [type='radio'], [type='range']) {
    height: calc(
        1rem * var(--moads-line-height) + var(--moads-form-element-spacing-vertical) * 2 + var(--moads-border-width) * 2
    );
}

fieldset {
    width: 100%;
    margin: 0;
    margin-bottom: var(--moads-spacing);
    padding: 0;
    border: 0;
}

fieldset legend,
label {
    display: block;
    margin-bottom: calc(var(--moads-spacing) * 0.375);
    color: var(--moads-color);
    font-weight: var(--moads-form-label-font-weight, var(--moads-font-weight));
}

fieldset legend {
    margin-bottom: calc(var(--moads-spacing) * 0.5);
}

button[type='submit'],
input:not([type='checkbox'], [type='radio']),
select,
textarea {
    width: 100%;
}

input:not([type='checkbox'], [type='radio'], [type='range'], [type='file']),
select,
textarea {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    padding: var(--moads-form-element-spacing-vertical) var(--moads-form-element-spacing-horizontal);
}

input,
select,
textarea {
    --moads-background-color: var(--moads-form-element-background-color);
    --moads-border-color: var(--moads-form-element-border-color);
    --moads-color: var(--moads-form-element-color);
    --moads-box-shadow: none;
    border: var(--moads-border-width) solid var(--moads-border-color);
    border-radius: var(--moads-border-radius);
    outline: 0;
    background-color: var(--moads-background-color);
    box-shadow: var(--moads-box-shadow);
    color: var(--moads-color);
    font-weight: var(--moads-font-weight);
    transition:
        background-color var(--moads-transition),
        border-color var(--moads-transition),
        color var(--moads-transition),
        box-shadow var(--moads-transition);
}

:where(select, textarea):not([readonly]):is(:active, :focus),
input:not([type='submit'], [type='button'], [type='reset'], [type='checkbox'], [type='radio'], [readonly]):is(
        :active,
        :focus
    ) {
    --moads-background-color: var(--moads-form-element-active-background-color);
}

:where(select, textarea):not([readonly]):is(:active, :focus),
input:not([type='submit'], [type='button'], [type='reset'], [role='switch'], [readonly]):is(:active, :focus) {
    --moads-border-color: var(--moads-form-element-active-border-color);
}

:where(select, textarea):not([readonly]):focus,
input:not([type='submit'], [type='button'], [type='reset'], [type='range'], [type='file'], [readonly]):focus {
    --moads-box-shadow: 0 0 0 var(--moads-outline-width) var(--moads-form-element-focus-color);
}

:where(fieldset[disabled]) :is(input:not([type='submit'], [type='button'], [type='reset']), select, textarea),
input:not([type='submit'], [type='button'], [type='reset'])[disabled],
label[aria-disabled='true'],
select[disabled],
textarea[disabled] {
    opacity: var(--moads-form-element-disabled-opacity);
    pointer-events: none;
}

label[aria-disabled='true'] input[disabled] {
    opacity: 1;
}

:where(input, select, textarea):not(
        [type='checkbox'],
        [type='radio'],
        [type='date'],
        [type='datetime-local'],
        [type='month'],
        [type='time'],
        [type='week'],
        [type='range']
    )[aria-invalid] {
    padding-right: calc(var(--moads-form-element-spacing-horizontal) + 1.5rem) !important;
    padding-left: var(--moads-form-element-spacing-horizontal);
    padding-inline-start: var(--moads-form-element-spacing-horizontal) !important;
    padding-inline-end: calc(var(--moads-form-element-spacing-horizontal) + 1.5rem) !important;
    background-position: center right 0.75rem;
    background-size: 1rem auto;
    background-repeat: no-repeat;
}

:where(input, select, textarea):not(
        [type='checkbox'],
        [type='radio'],
        [type='date'],
        [type='datetime-local'],
        [type='month'],
        [type='time'],
        [type='week'],
        [type='range']
    )[aria-invalid='false']:not(select) {
    background-image: var(--moads-icon-valid);
}

:where(input, select, textarea):not(
        [type='checkbox'],
        [type='radio'],
        [type='date'],
        [type='datetime-local'],
        [type='month'],
        [type='time'],
        [type='week'],
        [type='range']
    )[aria-invalid='true']:not(select) {
    background-image: var(--moads-icon-invalid);
}

:where(input, select, textarea)[aria-invalid='false'] {
    --moads-border-color: var(--moads-form-element-valid-border-color);
}

:where(input, select, textarea)[aria-invalid='false']:is(:active, :focus) {
    --moads-border-color: var(--moads-form-element-valid-active-border-color) !important
;
}

:where(input, select, textarea)[aria-invalid='false']:is(:active, :focus):not([type='checkbox'], [type='radio']) {
    --moads-box-shadow: 0 0 0 var(--moads-outline-width) var(--moads-form-element-valid-focus-color) !important
;
}

:where(input, select, textarea)[aria-invalid='true'] {
    --moads-border-color: var(--moads-form-element-invalid-border-color);
}

:where(input, select, textarea)[aria-invalid='true']:is(:active, :focus) {
    --moads-border-color: var(--moads-form-element-invalid-active-border-color) !important
;
}

:where(input, select, textarea)[aria-invalid='true']:is(:active, :focus):not([type='checkbox'], [type='radio']) {
    --moads-box-shadow: 0 0 0 var(--moads-outline-width) var(--moads-form-element-invalid-focus-color) !important
;
}

[dir='rtl']
    :where(input, select, textarea):not([type='checkbox'], [type='radio']):is(
        [aria-invalid],
        [aria-invalid='true'],
        [aria-invalid='false']
    ) {
    background-position: center left 0.75rem;
}

input::-webkit-input-placeholder,
input::placeholder,
select:invalid,
textarea::-webkit-input-placeholder,
textarea::placeholder {
    color: var(--moads-form-element-placeholder-color);
    opacity: 1;
}

input:not([type='checkbox'], [type='radio']),
select,
textarea {
    margin-bottom: var(--moads-spacing);
}

select::-ms-expand {
    border: 0;
    background-color: transparent;
}

select:not([multiple], [size]) {
    padding-right: calc(var(--moads-form-element-spacing-horizontal) + 1.5rem);
    padding-left: var(--moads-form-element-spacing-horizontal);
    padding-inline-start: var(--moads-form-element-spacing-horizontal);
    padding-inline-end: calc(var(--moads-form-element-spacing-horizontal) + 1.5rem);
    background-image: var(--moads-icon-chevron);
    background-position: center right 0.75rem;
    background-size: 1rem auto;
    background-repeat: no-repeat;
}

select[multiple] option:checked {
    background: var(--moads-form-element-selected-background-color);
    color: var(--moads-form-element-color);
}

[dir='rtl'] select:not([multiple], [size]) {
    background-position: center left 0.75rem;
}

textarea {
    display: block;
    resize: vertical;
}

textarea[aria-invalid] {
    --moads-icon-height: calc(
        1rem * var(--moads-line-height) + var(--moads-form-element-spacing-vertical) * 2 + var(--moads-border-width) * 2
    );
    background-position: top right 0.75rem !important;
    background-size: 1rem var(--moads-icon-height) !important;
}

:where(input, select, textarea, fieldset) + small {
    display: block;
    width: 100%;
    margin-top: calc(var(--moads-spacing) * -0.75);
    margin-bottom: var(--moads-spacing);
    color: var(--moads-muted-color);
}

:where(input, select, textarea, fieldset)[aria-invalid='false'] + small {
    color: var(--moads-ins-color);
}

:where(input, select, textarea, fieldset)[aria-invalid='true'] + small {
    color: var(--moads-del-color);
}

label > :where(input, select, textarea) {
    margin-top: calc(var(--moads-spacing) * 0.25);
}

label:has([type='checkbox'], [type='radio']) {
    width: -moz-fit-content;
    width: fit-content;
    cursor: pointer;
}

[type='checkbox'],
[type='radio'] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 1.25em;
    height: 1.25em;
    margin-top: -0.125em;
    margin-inline-end: 0.5em;
    border-width: var(--moads-border-width);
    vertical-align: middle;
    cursor: pointer;
}

[type='checkbox']::-ms-check,
[type='radio']::-ms-check {
    display: none;
}

[type='checkbox']:checked,
[type='checkbox']:checked:active,
[type='checkbox']:checked:focus,
[type='radio']:checked,
[type='radio']:checked:active,
[type='radio']:checked:focus {
    --moads-background-color: var(--moads-primary-background);
    --moads-border-color: var(--moads-primary-border);
    background-image: var(--moads-icon-checkbox);
    background-position: center;
    background-size: 0.75em auto;
    background-repeat: no-repeat;
}

[type='checkbox'] ~ label,
[type='radio'] ~ label {
    display: inline-block;
    margin-bottom: 0;
    cursor: pointer;
}

[type='checkbox'] ~ label:not(:last-of-type),
[type='radio'] ~ label:not(:last-of-type) {
    margin-inline-end: 1em;
}

[type='checkbox']:indeterminate {
    --moads-background-color: var(--moads-primary-background);
    --moads-border-color: var(--moads-primary-border);
    background-image: var(--moads-icon-minus);
    background-position: center;
    background-size: 0.75em auto;
    background-repeat: no-repeat;
}

[type='radio'] {
    border-radius: 50%;
}

[type='radio']:checked,
[type='radio']:checked:active,
[type='radio']:checked:focus {
    --moads-background-color: var(--moads-primary-inverse);
    border-width: 0.35em;
    background-image: none;
}

[type='checkbox'][role='switch'] {
    --moads-background-color: var(--moads-switch-background-color);
    --moads-color: var(--moads-switch-color);
    width: 2.25em;
    height: 1.25em;
    border: var(--moads-border-width) solid var(--moads-border-color);
    border-radius: 1.25em;
    background-color: var(--moads-background-color);
    line-height: 1.25em;
}

[type='checkbox'][role='switch']:not([aria-invalid]) {
    --moads-border-color: var(--moads-switch-background-color);
}

[type='checkbox'][role='switch']:before {
    display: block;
    aspect-ratio: 1;
    height: 100%;
    border-radius: 50%;
    background-color: var(--moads-color);
    box-shadow: var(--moads-switch-thumb-box-shadow);
    content: '';
    transition: margin 0.1s ease-in-out;
}

[type='checkbox'][role='switch']:focus {
    --moads-background-color: var(--moads-switch-background-color);
    --moads-border-color: var(--moads-switch-background-color);
}

[type='checkbox'][role='switch']:checked {
    --moads-background-color: var(--moads-switch-checked-background-color);
    --moads-border-color: var(--moads-switch-checked-background-color);
    background-image: none;
}

[type='checkbox'][role='switch']:checked::before {
    margin-inline-start: calc(2.25em - 1.25em);
}

[type='checkbox'][role='switch'][disabled] {
    --moads-background-color: var(--moads-border-color);
}

[type='checkbox'][aria-invalid='false']:checked,
[type='checkbox'][aria-invalid='false']:checked:active,
[type='checkbox'][aria-invalid='false']:checked:focus,
[type='checkbox'][role='switch'][aria-invalid='false']:checked,
[type='checkbox'][role='switch'][aria-invalid='false']:checked:active,
[type='checkbox'][role='switch'][aria-invalid='false']:checked:focus {
    --moads-background-color: var(--moads-form-element-valid-border-color);
}

[type='checkbox']:checked:active[aria-invalid='true'],
[type='checkbox']:checked:focus[aria-invalid='true'],
[type='checkbox']:checked[aria-invalid='true'],
[type='checkbox'][role='switch']:checked:active[aria-invalid='true'],
[type='checkbox'][role='switch']:checked:focus[aria-invalid='true'],
[type='checkbox'][role='switch']:checked[aria-invalid='true'] {
    --moads-background-color: var(--moads-form-element-invalid-border-color);
}

[type='checkbox'][aria-invalid='false']:checked,
[type='checkbox'][aria-invalid='false']:checked:active,
[type='checkbox'][aria-invalid='false']:checked:focus,
[type='checkbox'][role='switch'][aria-invalid='false']:checked,
[type='checkbox'][role='switch'][aria-invalid='false']:checked:active,
[type='checkbox'][role='switch'][aria-invalid='false']:checked:focus,
[type='radio'][aria-invalid='false']:checked,
[type='radio'][aria-invalid='false']:checked:active,
[type='radio'][aria-invalid='false']:checked:focus {
    --moads-border-color: var(--moads-form-element-valid-border-color);
}

[type='checkbox']:checked:active[aria-invalid='true'],
[type='checkbox']:checked:focus[aria-invalid='true'],
[type='checkbox']:checked[aria-invalid='true'],
[type='checkbox'][role='switch']:checked:active[aria-invalid='true'],
[type='checkbox'][role='switch']:checked:focus[aria-invalid='true'],
[type='checkbox'][role='switch']:checked[aria-invalid='true'],
[type='radio']:checked:active[aria-invalid='true'],
[type='radio']:checked:focus[aria-invalid='true'],
[type='radio']:checked[aria-invalid='true'] {
    --moads-border-color: var(--moads-form-element-invalid-border-color);
}

[type='color']::-webkit-color-swatch-wrapper {
    padding: 0;
}

[type='color']::-moz-focus-inner {
    padding: 0;
}

[type='color']::-webkit-color-swatch {
    border: 0;
    border-radius: calc(var(--moads-border-radius) * 0.5);
}

[type='color']::-moz-color-swatch {
    border: 0;
    border-radius: calc(var(--moads-border-radius) * 0.5);
}

input:not([type='checkbox'], [type='radio'], [type='range'], [type='file']):is(
        [type='date'],
        [type='datetime-local'],
        [type='month'],
        [type='time'],
        [type='week']
    ) {
    --moads-icon-position: 0.75rem;
    --moads-icon-width: 1rem;
    padding-right: calc(var(--moads-icon-width) + var(--moads-icon-position));
    background-image: var(--moads-icon-date);
    background-position: center right var(--moads-icon-position);
    background-size: var(--moads-icon-width) auto;
    background-repeat: no-repeat;
}

input:not([type='checkbox'], [type='radio'], [type='range'], [type='file'])[type='time'] {
    background-image: var(--moads-icon-time);
}

[type='date']::-webkit-calendar-picker-indicator,
[type='datetime-local']::-webkit-calendar-picker-indicator,
[type='month']::-webkit-calendar-picker-indicator,
[type='time']::-webkit-calendar-picker-indicator,
[type='week']::-webkit-calendar-picker-indicator {
    width: var(--moads-icon-width);
    margin-right: calc(var(--moads-icon-width) * -1);
    margin-left: var(--moads-icon-position);
    opacity: 0;
}

@-moz-document url-prefix() {
    [type='date'],
    [type='datetime-local'],
    [type='month'],
    [type='time'],
    [type='week'] {
        padding-right: var(--moads-form-element-spacing-horizontal) !important;
        background-image: none !important;
    }
}

[dir='rtl'] :is([type='date'], [type='datetime-local'], [type='month'], [type='time'], [type='week']) {
    text-align: right;
}

[type='file'] {
    --moads-color: var(--moads-muted-color);
    margin-left: calc(var(--moads-outline-width) * -1);
    padding: calc(var(--moads-form-element-spacing-vertical) * 0.5) 0;
    padding-left: var(--moads-outline-width);
    border: 0;
    border-radius: 0;
    background: 0 0;
}

[type='file']::file-selector-button {
    margin-right: calc(var(--moads-spacing) / 2);
    padding: calc(var(--moads-form-element-spacing-vertical) * 0.5) var(--moads-form-element-spacing-horizontal);
}

[type='file']:is(:hover, :active, :focus)::file-selector-button {
    --moads-background-color: var(--moads-secondary-hover-background);
    --moads-border-color: var(--moads-secondary-hover-border);
}

[type='file']:focus::file-selector-button {
    --moads-box-shadow: var(--moads-button-hover-box-shadow, 0 0 0 rgba(0, 0, 0, 0)),
        0 0 0 var(--moads-outline-width) var(--moads-secondary-focus);
}

[type='range'] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 100%;
    height: 1.25rem;
    background: 0 0;
}

[type='range']::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.375rem;
    border-radius: var(--moads-border-radius);
    background-color: var(--moads-range-border-color);
    -webkit-transition:
        background-color var(--moads-transition),
        box-shadow var(--moads-transition);
    transition:
        background-color var(--moads-transition),
        box-shadow var(--moads-transition);
}

[type='range']::-moz-range-track {
    width: 100%;
    height: 0.375rem;
    border-radius: var(--moads-border-radius);
    background-color: var(--moads-range-border-color);
    -moz-transition:
        background-color var(--moads-transition),
        box-shadow var(--moads-transition);
    transition:
        background-color var(--moads-transition),
        box-shadow var(--moads-transition);
}

[type='range']::-ms-track {
    width: 100%;
    height: 0.375rem;
    border-radius: var(--moads-border-radius);
    background-color: var(--moads-range-border-color);
    -ms-transition:
        background-color var(--moads-transition),
        box-shadow var(--moads-transition);
    transition:
        background-color var(--moads-transition),
        box-shadow var(--moads-transition);
}

[type='range']::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 1.25rem;
    height: 1.25rem;
    margin-top: -0.4375rem;
    border: 2px solid var(--moads-range-thumb-border-color);
    border-radius: 50%;
    background-color: var(--moads-range-thumb-color);
    cursor: pointer;
    -webkit-transition:
        background-color var(--moads-transition),
        transform var(--moads-transition);
    transition:
        background-color var(--moads-transition),
        transform var(--moads-transition);
}

[type='range']::-moz-range-thumb {
    -webkit-appearance: none;
    width: 1.25rem;
    height: 1.25rem;
    margin-top: -0.4375rem;
    border: 2px solid var(--moads-range-thumb-border-color);
    border-radius: 50%;
    background-color: var(--moads-range-thumb-color);
    cursor: pointer;
    -moz-transition:
        background-color var(--moads-transition),
        transform var(--moads-transition);
    transition:
        background-color var(--moads-transition),
        transform var(--moads-transition);
}

[type='range']::-ms-thumb {
    -webkit-appearance: none;
    width: 1.25rem;
    height: 1.25rem;
    margin-top: -0.4375rem;
    border: 2px solid var(--moads-range-thumb-border-color);
    border-radius: 50%;
    background-color: var(--moads-range-thumb-color);
    cursor: pointer;
    -ms-transition:
        background-color var(--moads-transition),
        transform var(--moads-transition);
    transition:
        background-color var(--moads-transition),
        transform var(--moads-transition);
}

[type='range']:active,
[type='range']:focus-within {
    --moads-range-border-color: var(--moads-range-active-border-color);
    --moads-range-thumb-color: var(--moads-range-thumb-active-color);
}

[type='range']:active::-webkit-slider-thumb {
    transform: scale(1.25);
}

[type='range']:active::-moz-range-thumb {
    transform: scale(1.25);
}

[type='range']:active::-ms-thumb {
    transform: scale(1.25);
}

input:not([type='checkbox'], [type='radio'], [type='range'], [type='file'])[type='search'] {
    padding-inline-start: calc(var(--moads-form-element-spacing-horizontal) + 1.75rem);
    background-image: var(--moads-icon-search);
    background-position: center left calc(var(--moads-form-element-spacing-horizontal) + 0.125rem);
    background-size: 1rem auto;
    background-repeat: no-repeat;
}

input:not([type='checkbox'], [type='radio'], [type='range'], [type='file'])[type='search'][aria-invalid] {
    padding-inline-start: calc(var(--moads-form-element-spacing-horizontal) + 1.75rem) !important;
    background-position:
        center left 1.125rem,
        center right 0.75rem;
}

input:not([type='checkbox'], [type='radio'], [type='range'], [type='file'])[type='search'][aria-invalid='false'] {
    background-image: var(--moads-icon-search), var(--moads-icon-valid);
}

input:not([type='checkbox'], [type='radio'], [type='range'], [type='file'])[type='search'][aria-invalid='true'] {
    background-image: var(--moads-icon-search), var(--moads-icon-invalid);
}

[dir='rtl'] :where(input):not([type='checkbox'], [type='radio'], [type='range'], [type='file'])[type='search'] {
    background-position: center right 1.125rem;
}

[dir='rtl']
    :where(input):not([type='checkbox'], [type='radio'], [type='range'], [type='file'])[type='search'][aria-invalid] {
    background-position:
        center right 1.125rem,
        center left 0.75rem;
}

details {
    display: block;
    margin-bottom: var(--moads-spacing);
}

details summary {
    line-height: 1rem;
    list-style-type: none;
    cursor: pointer;
    transition: color var(--moads-transition);
}

details summary:not([role]) {
    color: var(--moads-accordion-close-summary-color);
}

details summary::-webkit-details-marker {
    display: none;
}

details summary::marker {
    display: none;
}

details summary::-moz-list-bullet {
    list-style-type: none;
}

details summary::after {
    display: block;
    width: 1rem;
    height: 1rem;
    margin-inline-start: calc(var(--moads-spacing, 1rem) * 0.5);
    float: right;
    transform: rotate(-90deg);
    background-image: var(--moads-icon-chevron);
    background-position: right center;
    background-size: 1rem auto;
    background-repeat: no-repeat;
    content: '';
    transition: transform var(--moads-transition);
}

details summary:focus {
    outline: 0;
}

details summary:focus:not([role]) {
    color: var(--moads-accordion-active-summary-color);
}

details summary:focus-visible:not([role]) {
    outline: var(--moads-outline-width) solid var(--moads-primary-focus);
    outline-offset: calc(var(--moads-spacing, 1rem) * 0.5);
    color: var(--moads-primary);
}

details summary[role='button'] {
    width: 100%;
    text-align: left;
}

details summary[role='button']::after {
    height: calc(1rem * var(--moads-line-height, 1.5));
}

details[open] > summary {
    margin-bottom: var(--moads-spacing);
}

details[open] > summary:not([role]):not(:focus) {
    color: var(--moads-accordion-open-summary-color);
}

details[open] > summary::after {
    transform: rotate(0);
}

[dir='rtl'] details summary {
    text-align: right;
}

[dir='rtl'] details summary::after {
    float: left;
    background-position: left center;
}

article {
    margin-bottom: var(--moads-block-spacing-vertical);
    padding: var(--moads-block-spacing-vertical) var(--moads-block-spacing-horizontal);
    border-radius: var(--moads-border-radius);
    background: var(--moads-card-background-color);
    box-shadow: var(--moads-card-box-shadow);
}

article > footer,
article > header {
    margin-right: calc(var(--moads-block-spacing-horizontal) * -1);
    margin-left: calc(var(--moads-block-spacing-horizontal) * -1);
    padding: calc(var(--moads-block-spacing-vertical) * 0.66) var(--moads-block-spacing-horizontal);
    background-color: var(--moads-card-sectioning-background-color);
}

article > header {
    margin-top: calc(var(--moads-block-spacing-vertical) * -1);
    margin-bottom: var(--moads-block-spacing-vertical);
    border-bottom: var(--moads-border-width) solid var(--moads-card-border-color);
    border-top-right-radius: var(--moads-border-radius);
    border-top-left-radius: var(--moads-border-radius);
}

article > footer {
    margin-top: var(--moads-block-spacing-vertical);
    margin-bottom: calc(var(--moads-block-spacing-vertical) * -1);
    border-top: var(--moads-border-width) solid var(--moads-card-border-color);
    border-bottom-right-radius: var(--moads-border-radius);
    border-bottom-left-radius: var(--moads-border-radius);
}

[role='group']:not(.ccm-widget--buttons, .ccm-modal--footer),
[role='search'] {
    display: inline-flex;
    position: relative;
    width: 100%;
    margin-bottom: var(--moads-spacing);
    border-radius: var(--moads-border-radius);
    box-shadow: var(--moads-group-box-shadow, 0 0 0 transparent);
    vertical-align: middle;
    transition: box-shadow var(--moads-transition);
}

[role='group'] input:not([type='checkbox'], [type='radio']),
[role='group'] select,
[role='group'] > *,
[role='search'] input:not([type='checkbox'], [type='radio']),
[role='search'] select,
[role='search'] > * {
    position: relative;
    flex: 1 1 auto;
    margin-bottom: 0;
}

[role='group'] input:not([type='checkbox'], [type='radio']):not(:first-child),
[role='group'] select:not(:first-child),
[role='group'] > :not(:first-child),
[role='search'] input:not([type='checkbox'], [type='radio']):not(:first-child),
[role='search'] select:not(:first-child),
[role='search'] > :not(:first-child) {
    margin-left: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

[role='group'] input:not([type='checkbox'], [type='radio']):not(:last-child),
[role='group'] select:not(:last-child),
[role='group'] > :not(:last-child),
[role='search'] input:not([type='checkbox'], [type='radio']):not(:last-child),
[role='search'] select:not(:last-child),
[role='search'] > :not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

[role='group'] input:not([type='checkbox'], [type='radio']):focus,
[role='group'] select:focus,
[role='group'] > :focus,
[role='search'] input:not([type='checkbox'], [type='radio']):focus,
[role='search'] select:focus,
[role='search'] > :focus {
    z-index: 2;
}

[role='group'] [role='button']:not(:first-child, .ccm-must-show, .button-1),
[role='group'] [type='button']:not(:first-child),
[role='group'] [type='reset']:not(:first-child),
[role='group'] [type='submit']:not(:first-child),
[role='group'] button:not(:first-child),
[role='group'] input:not([type='checkbox'], [type='radio']):not(:first-child),
[role='group'] select:not(:first-child),
[role='search'] [role='button']:not(:first-child),
[role='search'] [type='button']:not(:first-child),
[role='search'] [type='reset']:not(:first-child),
[role='search'] [type='submit']:not(:first-child),
[role='search'] button:not(:first-child),
[role='search'] input:not([type='checkbox'], [type='radio']):not(:first-child),
[role='search'] select:not(:first-child) {
    margin-left: calc(var(--moads-border-width) * -1);
}

[role='group'] [role='button']:not(.ccm-must-show, .button-1),
[role='group'] [type='button'],
[role='group'] [type='reset'],
[role='group'] [type='submit'],
[role='group'] button,
[role='search'] [role='button'],
[role='search'] [type='button'],
[role='search'] [type='reset'],
[role='search'] [type='submit'],
[role='search'] button {
    width: auto;
}

@supports selector(:has(*)) {
    [role='group']:has(button:focus, [type='submit']:focus, [type='button']:focus, [role='button']:focus),
    [role='search']:has(button:focus, [type='submit']:focus, [type='button']:focus, [role='button']:focus) {
        --moads-group-box-shadow: var(--moads-group-box-shadow-focus-with-button);
    }

    [role='group']:has(button:focus, [type='submit']:focus, [type='button']:focus, [role='button']:focus)
        input:not([type='checkbox'], [type='radio']),
    [role='group']:has(button:focus, [type='submit']:focus, [type='button']:focus, [role='button']:focus) select,
    [role='search']:has(button:focus, [type='submit']:focus, [type='button']:focus, [role='button']:focus)
        input:not([type='checkbox'], [type='radio']),
    [role='search']:has(button:focus, [type='submit']:focus, [type='button']:focus, [role='button']:focus) select {
        border-color: transparent;
    }

    [role='group']:has(input:not([type='submit'], [type='button']):focus, select:focus),
    [role='search']:has(input:not([type='submit'], [type='button']):focus, select:focus) {
        --moads-group-box-shadow: var(--moads-group-box-shadow-focus-with-input);
    }

    [role='group']:has(input:not([type='submit'], [type='button']):focus, select:focus) [role='button'],
    [role='group']:has(input:not([type='submit'], [type='button']):focus, select:focus) [type='button'],
    [role='group']:has(input:not([type='submit'], [type='button']):focus, select:focus) [type='submit'],
    [role='group']:has(input:not([type='submit'], [type='button']):focus, select:focus) button,
    [role='search']:has(input:not([type='submit'], [type='button']):focus, select:focus) [role='button'],
    [role='search']:has(input:not([type='submit'], [type='button']):focus, select:focus) [type='button'],
    [role='search']:has(input:not([type='submit'], [type='button']):focus, select:focus) [type='submit'],
    [role='search']:has(input:not([type='submit'], [type='button']):focus, select:focus) button {
        --moads-button-box-shadow: 0 0 0 var(--moads-border-width) var(--moads-primary-border);
        --moads-button-hover-box-shadow: 0 0 0 var(--moads-border-width) var(--moads-primary-hover-border);
    }

    [role='group'] [role='button']:not(.ccm-must-show, .button-1):focus,
    [role='group'] [type='button']:focus,
    [role='group'] [type='reset']:focus,
    [role='group'] [type='submit']:focus,
    [role='group'] button:focus,
    [role='search'] [role='button']:focus,
    [role='search'] [type='button']:focus,
    [role='search'] [type='reset']:focus,
    [role='search'] [type='submit']:focus,
    [role='search'] button:focus {
        box-shadow: none;
    }
}

[role='search'] > :first-child {
    border-top-left-radius: 5rem;
    border-bottom-left-radius: 5rem;
}

[role='search'] > :last-child {
    border-top-right-radius: 5rem;
    border-bottom-right-radius: 5rem;
}

[aria-busy='true']:not(input, select, textarea, html) {
    white-space: nowrap;
}

[aria-busy='true']:not(input, select, textarea, html)::before {
    display: inline-block;
    width: 1em;
    height: 1em;
    background-image: var(--moads-icon-loading);
    background-size: 1em auto;
    background-repeat: no-repeat;
    content: '';
    vertical-align: -0.125em;
}

[aria-busy='true']:not(input, select, textarea, html):not(:empty)::before {
    margin-inline-end: calc(var(--moads-spacing) * 0.5);
}

[aria-busy='true']:not(input, select, textarea, html):empty {
    text-align: center;
}

[role='button'][aria-busy='true'],
[type='button'][aria-busy='true'],
[type='reset'][aria-busy='true'],
[type='submit'][aria-busy='true'],
a[aria-busy='true'],
button[aria-busy='true'] {
    pointer-events: none;
}

:root {
    --moads-scrollbar-width: 0px;
}

dialog {
    display: flex;
    z-index: 999;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    align-items: center;
    justify-content: center;
    width: inherit;
    min-width: 100%;
    height: inherit;
    min-height: 100%;
    padding: 0;
    border: 0;
    -webkit-backdrop-filter: var(--moads-modal-overlay-backdrop-filter);
    backdrop-filter: var(--moads-modal-overlay-backdrop-filter);
    background-color: var(--moads-modal-overlay-background-color);
    color: var(--moads-color);
}

dialog article {
    width: 100%;
    max-height: calc(100vh - var(--moads-spacing) * 2);
    margin: var(--moads-spacing);
    overflow: auto;
}

@media (min-width: 576px) {
    dialog article {
        max-width: 510px;
    }
}

@media (min-width: 768px) {
    dialog article {
        max-width: 700px;
    }
}

dialog article > header > * {
    margin-bottom: 0;
}

dialog article > header :is(a, button)[rel='prev'] {
    margin: 0;
    margin-left: var(--moads-spacing);
    padding: 0;
    float: right;
}

dialog article > footer {
    text-align: right;
}

dialog article > footer [role='button'],
dialog article > footer button {
    margin-bottom: 0;
}

dialog article > footer [role='button']:not(:first-of-type),
dialog article > footer button:not(:first-of-type) {
    margin-left: calc(var(--moads-spacing) * 0.5);
}

dialog article :is(a, button)[rel='prev'] {
    display: block;
    width: 1rem;
    height: 1rem;
    margin-top: calc(var(--moads-spacing) * -1);
    margin-bottom: var(--moads-spacing);
    margin-left: auto;
    border: none;
    background-image: var(--moads-icon-close);
    background-position: center;
    background-size: auto 1rem;
    background-repeat: no-repeat;
    background-color: transparent;
    opacity: 0.5;
    transition: opacity var(--moads-transition);
}

dialog article :is(a, button)[rel='prev']:is([aria-current]:not([aria-current='false']), :hover, :active, :focus) {
    opacity: 1;
}

dialog:not([open]),
dialog[open='false'] {
    display: none;
}

:where(nav li)::before {
    float: left;
    content: '​';
}

nav,
nav ul {
    display: flex;
}

nav {
    justify-content: space-between;
    align-items: center;
    overflow: visible;
}

nav ol,
nav ul {
    align-items: center;
    margin-bottom: 0;
    padding: 0;
    list-style: none;
}

nav ol:first-of-type,
nav ul:first-of-type {
    margin-left: calc(var(--moads-nav-element-spacing-horizontal) * -1);
}

nav ol:last-of-type,
nav ul:last-of-type {
    margin-right: calc(var(--moads-nav-element-spacing-horizontal) * -1);
}

nav li {
    display: inline-block;
    margin: 0;
    padding: var(--moads-nav-element-spacing-vertical) var(--moads-nav-element-spacing-horizontal);
}

nav li :where(a, [role='link']) {
    display: inline-block;
    margin: calc(var(--moads-nav-link-spacing-vertical) * -1) calc(var(--moads-nav-link-spacing-horizontal) * -1);
    padding: var(--moads-nav-link-spacing-vertical) var(--moads-nav-link-spacing-horizontal);
    border-radius: var(--moads-border-radius);
}

nav li :where(a, [role='link']):not(:hover) {
    text-decoration: none;
}

nav li [role='button'],
nav li [type='button'],
nav li button,
nav li input:not([type='checkbox'], [type='radio'], [type='range'], [type='file']),
nav li select {
    height: auto;
    margin-right: inherit;
    margin-bottom: 0;
    margin-left: inherit;
    padding: calc(var(--moads-nav-link-spacing-vertical) - var(--moads-border-width) * 2)
        var(--moads-nav-link-spacing-horizontal);
}

nav[aria-label='breadcrumb'] {
    align-items: center;
    justify-content: start;
}

nav[aria-label='breadcrumb'] ul li:not(:first-child) {
    margin-inline-start: var(--moads-nav-link-spacing-horizontal);
}

nav[aria-label='breadcrumb'] ul li a {
    margin: calc(var(--moads-nav-link-spacing-vertical) * -1) 0;
    margin-inline-start: calc(var(--moads-nav-link-spacing-horizontal) * -1);
}

nav[aria-label='breadcrumb'] ul li:not(:last-child)::after {
    display: inline-block;
    position: absolute;
    width: calc(var(--moads-nav-link-spacing-horizontal) * 4);
    margin: 0 calc(var(--moads-nav-link-spacing-horizontal) * -1);
    content: var(--moads-nav-breadcrumb-divider);
    color: var(--moads-muted-color);
    text-align: center;
    text-decoration: none;
    white-space: nowrap;
}

nav[aria-label='breadcrumb'] a[aria-current]:not([aria-current='false']) {
    background-color: transparent;
    color: inherit;
    text-decoration: none;
    pointer-events: none;
}

aside li,
aside nav,
aside ol,
aside ul {
    display: block;
}

aside li {
    padding: calc(var(--moads-nav-element-spacing-vertical) * 0.5) var(--moads-nav-element-spacing-horizontal);
}

aside li a {
    display: block;
}

aside li [role='button'] {
    margin: inherit;
}

[dir='rtl'] nav[aria-label='breadcrumb'] ul li:not(:last-child) ::after {
    content: '\\';
}

progress {
    display: inline-block;
    vertical-align: baseline;
}

progress {
    -webkit-appearance: none;
    -moz-appearance: none;
    display: inline-block;
    appearance: none;
    width: 100%;
    height: 0.5rem;
    margin-bottom: calc(var(--moads-spacing) * 0.5);
    overflow: hidden;
    border: 0;
    border-radius: var(--moads-border-radius);
    background-color: var(--moads-progress-background-color);
    color: var(--moads-progress-color);
}

progress::-webkit-progress-bar {
    border-radius: var(--moads-border-radius);
    background: 0 0;
}

progress[value]::-webkit-progress-value {
    background-color: var(--moads-progress-color);
    -webkit-transition: inline-size var(--moads-transition);
    transition: inline-size var(--moads-transition);
}

progress::-moz-progress-bar {
    background-color: var(--moads-progress-color);
}

@media (prefers-reduced-motion: no-preference) {
    progress:indeterminate {
        background: var(--moads-progress-background-color)
            linear-gradient(to right, var(--moads-progress-color) 30%, var(--moads-progress-background-color) 30%) top
            left/150% 150% no-repeat;
        animation: progress-indeterminate 1s linear infinite;
    }

    progress:indeterminate[value]::-webkit-progress-value {
        background-color: transparent;
    }

    progress:indeterminate::-moz-progress-bar {
        background-color: transparent;
    }
}

@media (prefers-reduced-motion: no-preference) {
    [dir='rtl'] progress:indeterminate {
        animation-direction: reverse;
    }
}

@keyframes progress-indeterminate {
    0% {
        background-position: 200% 0;
    }

    100% {
        background-position: -200% 0;
    }
}

[data-tooltip] {
    position: relative;
}

[data-tooltip]:not(a, button, input) {
    border-bottom: 1px dotted;
    text-decoration: none;
    cursor: help;
}

[data-tooltip]::after,
[data-tooltip]::before,
[data-tooltip][data-placement='top']::after,
[data-tooltip][data-placement='top']::before {
    display: block;
    z-index: 99;
    position: absolute;
    bottom: 100%;
    left: 50%;
    padding: 0.25rem 0.5rem;
    overflow: hidden;
    transform: translate(-50%, -0.25rem);
    border-radius: var(--moads-border-radius);
    background: var(--moads-tooltip-background-color);
    content: attr(data-tooltip);
    color: var(--moads-tooltip-color);
    font-style: normal;
    font-weight: var(--moads-font-weight);
    font-size: 0.875rem;
    text-decoration: none;
    text-overflow: ellipsis;
    white-space: nowrap;
    opacity: 0;
    pointer-events: none;
}

[data-tooltip]::after,
[data-tooltip][data-placement='top']::after {
    padding: 0;
    transform: translate(-50%, 0);
    border-top: 0.3rem solid;
    border-right: 0.3rem solid transparent;
    border-left: 0.3rem solid transparent;
    border-radius: 0;
    background-color: transparent;
    content: '';
    color: var(--moads-tooltip-background-color);
}

[data-tooltip][data-placement='bottom']::after,
[data-tooltip][data-placement='bottom']::before {
    top: 100%;
    bottom: auto;
    transform: translate(-50%, 0.25rem);
}

[data-tooltip][data-placement='bottom']:after {
    transform: translate(-50%, -0.3rem);
    border: 0.3rem solid transparent;
    border-bottom: 0.3rem solid;
}

[data-tooltip][data-placement='left']::after,
[data-tooltip][data-placement='left']::before {
    top: 50%;
    right: 100%;
    bottom: auto;
    left: auto;
    transform: translate(-0.25rem, -50%);
}

[data-tooltip][data-placement='left']:after {
    transform: translate(0.3rem, -50%);
    border: 0.3rem solid transparent;
    border-left: 0.3rem solid;
}

[data-tooltip][data-placement='right']::after,
[data-tooltip][data-placement='right']::before {
    top: 50%;
    right: auto;
    bottom: auto;
    left: 100%;
    transform: translate(0.25rem, -50%);
}

[data-tooltip][data-placement='right']:after {
    transform: translate(-0.3rem, -50%);
    border: 0.3rem solid transparent;
    border-right: 0.3rem solid;
}

[data-tooltip]:focus::after,
[data-tooltip]:focus::before,
[data-tooltip]:hover::after,
[data-tooltip]:hover::before {
    opacity: 1;
}

@media (hover: hover) and (pointer: fine) {
    [data-tooltip]:focus::after,
    [data-tooltip]:focus::before,
    [data-tooltip]:hover::after,
    [data-tooltip]:hover::before {
        --moads-tooltip-slide-to: translate(-50%, -0.25rem);
        transform: translate(-50%, 0.75rem);
        animation-duration: 0.2s;
        animation-fill-mode: forwards;
        animation-name: tooltip-slide;
        opacity: 0;
    }

    [data-tooltip]:focus::after,
    [data-tooltip]:hover::after {
        --moads-tooltip-caret-slide-to: translate(-50%, 0rem);
        transform: translate(-50%, -0.25rem);
        animation-name: tooltip-caret-slide;
    }

    [data-tooltip][data-placement='bottom']:focus::after,
    [data-tooltip][data-placement='bottom']:focus::before,
    [data-tooltip][data-placement='bottom']:hover::after,
    [data-tooltip][data-placement='bottom']:hover::before {
        --moads-tooltip-slide-to: translate(-50%, 0.25rem);
        transform: translate(-50%, -0.75rem);
        animation-name: tooltip-slide;
    }

    [data-tooltip][data-placement='bottom']:focus::after,
    [data-tooltip][data-placement='bottom']:hover::after {
        --moads-tooltip-caret-slide-to: translate(-50%, -0.3rem);
        transform: translate(-50%, -0.5rem);
        animation-name: tooltip-caret-slide;
    }

    [data-tooltip][data-placement='left']:focus::after,
    [data-tooltip][data-placement='left']:focus::before,
    [data-tooltip][data-placement='left']:hover::after,
    [data-tooltip][data-placement='left']:hover::before {
        --moads-tooltip-slide-to: translate(-0.25rem, -50%);
        transform: translate(0.75rem, -50%);
        animation-name: tooltip-slide;
    }

    [data-tooltip][data-placement='left']:focus::after,
    [data-tooltip][data-placement='left']:hover::after {
        --moads-tooltip-caret-slide-to: translate(0.3rem, -50%);
        transform: translate(0.05rem, -50%);
        animation-name: tooltip-caret-slide;
    }

    [data-tooltip][data-placement='right']:focus::after,
    [data-tooltip][data-placement='right']:focus::before,
    [data-tooltip][data-placement='right']:hover::after,
    [data-tooltip][data-placement='right']:hover::before {
        --moads-tooltip-slide-to: translate(0.25rem, -50%);
        transform: translate(-0.75rem, -50%);
        animation-name: tooltip-slide;
    }

    [data-tooltip][data-placement='right']:focus::after,
    [data-tooltip][data-placement='right']:hover::after {
        --moads-tooltip-caret-slide-to: translate(-0.3rem, -50%);
        transform: translate(-0.05rem, -50%);
        animation-name: tooltip-caret-slide;
    }
}

@keyframes tooltip-slide {
    to {
        transform: var(--moads-tooltip-slide-to);
        opacity: 1;
    }
}

@keyframes tooltip-caret-slide {
    50% {
        opacity: 0;
    }

    to {
        transform: var(--moads-tooltip-caret-slide-to);
        opacity: 1;
    }
}

[aria-controls] {
    cursor: pointer;
}

[aria-disabled='true'],
[disabled] {
    cursor: not-allowed;
}

[aria-hidden='false'][hidden] {
    display: initial;
}

[aria-hidden='false'][hidden]:not(:focus) {
    clip: rect(0, 0, 0, 0);
    position: absolute;
}

[tabindex],
a,
area,
button,
input,
label,
select,
summary,
textarea {
    -ms-touch-action: manipulation;
}

[dir='rtl'] {
    direction: rtl;
}

@media (prefers-reduced-motion: reduce) {
    :not([aria-busy='true']),
    :not([aria-busy='true'])::after,
    :not([aria-busy='true'])::before {
        background-attachment: initial !important;
        animation-duration: 1ms !important;
        animation-delay: -1ms !important;
        animation-iteration-count: 1 !important;
        scroll-behavior: auto !important;
        transition-delay: 0s !important;
        transition-duration: 0s !important;
    }
}
