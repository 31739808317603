/* Importing variables for the default theme */
@import 'assets/scss/variables';

/* Importing variables for the default theme */
@import 'assets/scss/defaults';

/* Importing variables for the default theme */
@import 'assets/scss/responsive';

/* Importing variables for the default theme */
@import 'assets/scss/main';

*,
html {
    // scroll-behavior: smooth !important;
}

html {
    overflow-x: hidden;
}

app-root {
    display: flex;
    flex-direction: column;
    min-height: 100dvh;
    // scroll-behavior: smooth !important;
}

app-contact-success,
app-not-found {
    flex: 1;
}

.app-start {
    view-transition-name: app-start;
}

section {
    position: relative;
    padding-block-start: 5rem;
    padding-block-end: 3rem;
    margin-bottom: 0;

    h1 {
        font-family: var(--moads-font-family-heading);
        // font-size: 72px;
        // font-size: 3.815rem;
        font-weight: 580;
        line-height: 1.14;
        position: relative;
        // margin-bottom: 5rem;
        word-spacing: 3px;
    }

    h2 {
        font-family: var(--moads-font-family-heading);
        // font-size: 48px;
        // font-size: 3.052rem;
        font-weight: 580;
        letter-spacing: 1.2px;
        word-spacing: 2px;
        // margin-bottom: 2rem;
    }

    h3 {
        font-family: var(--moads-font-family-heading);
        // font-size: 48px;
        // font-size: 2.441rem;
        font-weight: 580;
        // margin-bottom: 2rem;
        // color: var(--moads-h1-color);
        word-spacing: 2px;
    }

    h4 {
        font-family: var(--moads-font-family-heading);
        // font-size: 56px;
        // font-size: 1.953rem;
        font-weight: 580;
        // color: var(--moads-h1-color);
        word-spacing: 1px;
    }

    h5 {
        font-family: var(--moads-font-family-heading);
        // font-size: 36px;
        // font-size: 1.563rem;
        font-weight: 580;
        // color: var(--moads-h1-color);
    }

    h6 {
        font-family: var(--moads-font-family-heading);
        // font-size: 1.25rem;
        // line-height: 1.14;
        // color: var(--moads-h1-color);
        font-weight: 580;
    }

    .brighter {
        color: var(--moads-h1-color) !important;
    }

    .emphasized {
        display: block;
        font-family: var(--moads-font-family-heading);
        font-size: 1.15rem;
        font-weight: 580;
        line-height: 1.4;
        word-spacing: 2px;
        letter-spacing: 0.6px;
        color: var(--moads-h3-color);
        margin: var(--moads-typography-spacing-vertical) 0;
    }

    small {
        font-size: 0.8rem;
        color: var(--moads-muted-color);
    }

    app-bento {
        display: block;
    }

    .bento {
        .bento__item {
            h3 {
                margin: calc(var(--moads-typography-spacing-vertical) * 1.25) 0;
            }

            .emphasized {
                margin: var(--moads-typography-spacing-vertical) 0;
            }

            .icon {
                margin-bottom: 2rem;
                display: block;
                img {
                    filter: var(--moads-svg-filter);
                }
            }

            .bento__item-icon-wrapper {
                display: block;
                position: relative;
                overflow: hidden;
                // margin-bottom: calc(var(--moads-typography-spacing-vertical) * 2);
                width: 82px;
                height: 82px;
                border-radius: 50%;
                padding: 2px;

                &.autohandler {
                    &::before {
                        background-color: #fb8c15;
                        background-image: linear-gradient(to right, #fb8c15 0%, #fda73a 51%, #fb8c15 100%);
                    }
                }

                &.publisher {
                    &::before {
                        background-color: #4d69e2;
                        background-image: linear-gradient(to right, #4d69e2 0%, #7090ea 51%, #4d69e2 100%);
                    }
                }

                .icon {
                    display: block;
                    position: relative;
                    padding: 39px;
                    background: var(--moads-card-background-color);
                    border-radius: 50%;
                    margin-bottom: 0;
                }

                img {
                    width: 38px;
                    height: 38px;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    filter: var(--moads-svg-filter);
                }

                &::before {
                    position: absolute;
                    top: -10%;
                    left: -10%;
                    width: 120%;
                    height: 120%;
                    content: '';
                    background-color: var(--moads-muted-border-color);
                    filter: blur(5px);
                }
            }
        }
    }

    .badge {
        font-size: 16px;
        font-weight: 500;
        padding: 3px 16px;
        color: var(--moads-h1-color);
        border: 1px solid var(--moads-muted-color);
        border-radius: 20px;
        display: inline-block;
        margin-bottom: 1rem;
    }

    .badge-circle {
        width: 44px;
        height: 44px;
        border-radius: 50%;
        color: var(--moads-h1-color);
        background: var(--moads-secondary-background);
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 1rem;
    }
}

.border-top {
    border-top: 1px solid var(--moads-muted-border-color);
    padding-top: 1rem;
}

.p-bot-1 {
    padding-bottom: 1rem;
}

.mt-0 {
    margin-top: 0;
}

.mb-0 {
    margin-bottom: 0;
}

.buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    margin-block: 1rem;
}

.gradient {
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    background-color: #fb8c15;
    background-image: linear-gradient(to right, #fda73a 0%, #fb8c15 42%, #7090ea 79%, #4d69e2 100%);

    &.autohandler {
        background-color: #fb8c15;
        background-image: linear-gradient(to right, #fb8c15 0%, #fda73a 51%, #fb8c15 100%);
    }

    &.publisher {
        background-color: #4d69e2;
        background-image: linear-gradient(to right, #4d69e2 0%, #7090ea 51%, #4d69e2 100%);
    }
}

button {
    font-size: 16px;
    line-height: 1;
    font-weight: 600;
    padding: 18px 28px;
    outline: none;
    color: white;
    display: flex;
    border: none;
    transition: all 0.3s ease;

    span {
        // text-shadow: 0px 2px 4px rgba(19, 23, 31, 0.55);
    }

    &.primary {
        // background: #fb8c15;
        // background-image: linear-gradient(
        //     to right,
        //     #fb8c15 0%,
        //     #ffc837 51%,
        //     #fb8c15 100%
        // );
        // transition: 0.5s;
        // background-size: 200% auto;
        margin-right: 8px;
    }

    &.secondary {
        // background: #4d69e2;
        // background-image: linear-gradient(
        //     to right,
        //     #4d69e2 0%,
        //     #7090ea 51%,
        //     #4d69e2 100%
        // );
        // transition: 0.5s;
        // background-size: 200% auto;
    }

    &.alternate {
        background: #fb8c15;
        background-image: linear-gradient(to right, #ffc837 0%, #fb8c15 42%, #8e54e9 79%, #4d69e2 100%);
        transition: 0.5s;
        background-size: 150% auto;
    }

    .arrow {
        width: 8px;
        height: 8px;
        border-right: 2px solid currentColor;
        border-bottom: 2px solid currentColor;
        position: relative;
        transform: rotate(-45deg);
        margin: 4px 4px 0 14px;
        transition: all 0.3s ease;
    }

    .arrow::before {
        display: block;
        background-color: currentColor;
        width: 3px;
        transform-origin: bottom right;
        height: 2px;
        position: absolute;
        opacity: 0;
        bottom: calc(-2px / 2);
        transform: rotate(45deg);
        transition: all 0.3s ease;
        content: '';
        right: 0;
    }

    &:hover > .arrow {
        transform: rotate(-45deg) translate(4px, 4px);
        border-color: text-hover-color;
    }

    &:hover > .arrow::before {
        opacity: 1;
        width: 14px;
    }
}

.link {
    display: flex;
    align-items: center;
    font-family: var(--moads-font-family-heading);
    font-weight: 580;
    letter-spacing: 0.6px;
    text-decoration: none;

    &.primary {
        color: var(--moads-logo-primary);
        border-color: var(--moads-logo-primary) !important;
    }

    &.secondary {
        color: var(--moads-logo-secondary);
        border-color: var(--moads-logo-secondary) !important;
    }

    &.outline {
        display: inline-block;
        padding: 14px 32px;
        border: 2px solid currentColor !important;
        background-color: transparent !important;
        border-radius: 6px;
        color: var(--moads-secondary);
        transition: 0.5s;
        border-color: var(--moads-form-element-border-color) !important;

        &:hover {
            filter: brightness(1.5);
        }
    }

    &.primary {
        color: var(--moads-primary);
    }

    .arrow {
        width: 8px;
        height: 8px;
        border-right: 2px solid currentColor;
        border-bottom: 2px solid currentColor;
        position: relative;
        transform: rotate(-45deg);
        margin: 4px 4px 3px 16px;
        transition: all 0.3s ease;
        display: inline-block;
    }

    .arrow::before {
        display: block;
        background-color: currentColor;
        width: 3px;
        transform-origin: bottom right;
        height: 2px;
        position: absolute;
        opacity: 0;
        bottom: calc(-2px / 2);
        transform: rotate(45deg);
        transition: all 0.3s ease;
        content: '';
        right: 0;
    }

    &:hover > .arrow {
        transform: rotate(-45deg) translate(4px, 4px);
        border-color: text-hover-color;
    }

    &:hover > .arrow::before {
        opacity: 1;
        width: 14px;
    }
}

.link.primary,
.link-primary,
.primary-link {
    color: var(--moads-primary);
    transition: 0.5s;
    font-weight: 600;
    text-decoration: none;

    .arrow {
        border-color: var(--moads-primary);
    }

    &:hover {
        text-decoration: none;
    }
}

.link.secondary,
.link-secondary,
.secondary-link {
    color: #677ff9;
    transition: 0.5s;
    font-weight: 600;
    text-decoration: none;

    .arrow {
        border-color: #677ff9;
    }

    &:hover {
        text-decoration: none;
    }
}

.button-1 {
    padding-bottom: 20px;
    border: 0;
    font-size: 18px;
    letter-spacing: 0.4px;
    transition: all 100ms ease-in-out;
    border-radius: 20px;
    color: #181c25;
    font-family: var(--moads-font-family-heading);
    background: #fff;
    box-shadow:
        rgba(99, 99, 99, 0.2) 0 2px 8px 0,
        inset 0px -6px 0px rgba(0, 0, 0, 0.1),
        inset 0px -2px 0px rgba(0, 0, 0, 0.15);

    &.primary {
        background-image: linear-gradient(135deg, #fb8c15 0%, #ffc837 51%, #fb8c15 100%);
        color: #181c25;
        transition: all 100ms ease-in-out;
    }

    &.secondary {
        background: #4d69e2;
        color: #fff;
        transition: all 100ms ease-in-out;
    }
}

.button-1:hover {
    filter: brightness(0.98);
}

.button-1:active {
    filter: brightness(0.97);
    padding-top: 20px;
    padding-bottom: 18px;
    box-shadow:
        rgba(99, 99, 99, 0.2) 0 2px 6px 0,
        inset 0px -1px 0px rgba(0, 0, 0, 0.15);
}

.speed {
    position: relative;
    transition: all 0.5s !important;
    display: inline-flex;
    overflow: hidden;
    mask-image: none;
    -webkit-text-fill-color: initial;

    span {
        position: absolute;
        display: block;
        opacity: 0.8;

        &:nth-child(1) {
            bottom: 0;
            right: 0;
            width: 100%;
            height: 3px;
            background: linear-gradient(90deg, transparent, var(--moads-muted-color));
            animation: animate1 2s ease-in-out infinite;
        }
    }

    &.gradient {
        background-color: #4d69e2;
        background-image: linear-gradient(to right, #4d69e2 0%, #7090ea 51%, #4d69e2 100%);

        span {
            background: linear-gradient(90deg, transparent, #7090ea);
        }
    }
}

// .speed span:nth-child(2) {
//     top: -100%;
//     right: 0;
//     width: 3px;
//     height: 100%;
//     background: linear-gradient(180deg, transparent, var(--moads-muted-color));
//     animation: animate2 2s ease-in-out infinite;
//     animation-delay: 0.3s;
// }

// .speed span:nth-child(3) {
//     bottom: 0;
//     right: 0;
//     width: 100%;
//     height: 3px;
//     background: linear-gradient(270deg, transparent, var(--moads-muted-color));
//     animation: animate3 2s ease-in-out infinite;
//     animation-delay: 0.6s;
// }

// .speed span:nth-child(4) {
//     bottom: -100%;
//     left: 0;
//     width: 3px;
//     height: 100%;
//     background: linear-gradient(360deg, transparent, var(--moads-muted-color));
//     animation: animate4 2s ease-in-out infinite;
//     animation-delay: 0.9s;
// }

.button-2 {
    position: relative;
    padding: 19px 32px;
    text-decoration: none;
    transition: all 0.5s !important;
    font-weight: 580;
    letter-spacing: 0.6px;
    overflow: hidden;
    border: none;
    font-size: 0.9rem;
    border-radius: 12px;
    color: var(--moads-h1-color);
    font-family: var(--moads-font-family-heading);
    background: var(--moads-card-background-color);
    box-shadow:
        rgba(99, 99, 99, 0.2) 0 2px 8px 0,
        inset 0px -6px 0px rgba(0, 0, 0, 0.25);

    &.reverse {
        color: var(--moads-card-background-color);
        background: var(--moads-h1-color);

        // &::before {
        //     position: absolute;
        //     top: -1%;
        //     left: -1%;
        //     width: 101%;
        //     height: 101%;
        //     content: "";
        //     background-color: #3a4dd6;
        //     background-image: linear-gradient(120deg, #fb8c15 0%, #fda73a 44%, #3a4dd6 55%, #4d69e2 100%);
        //     filter: blur(15px);
        //     z-index: -1;
        //     border-radius: 50%;
        // }
    }

    &.primary {
        color: #181c25;
        background-color: #fb8c15;
        background-image: linear-gradient(to right, #fb8c15 0%, #fda73a 51%, #fb8c15 100%);
        background-size: inherit;
    }

    &.secondary {
        color: #f0f1f3;
        background-color: #4d69e2;
        background-image: linear-gradient(135deg, #3a4dd6 0%, #4d69e2 100%);
        background-size: inherit;
    }
}

.button-2:hover {
    filter: brightness(0.97);
    padding-top: 20px;
    padding-bottom: 18px;
    box-shadow:
        rgba(99, 99, 99, 0.2) 0 2px 6px 0,
        inset 0px 0 0px rgba(0, 0, 0, 0.25);
}

.button-2 span {
    position: absolute;
    display: block;
    opacity: 0.8;
}

.button-2 .arrow {
    transition: all 0.5s ease;
}

.button-2 .arrow::before {
    transition: all 0.5s ease;
}

.button-2 span:nth-child(1) {
    top: 0;
    left: 0;
    width: 100%;
    height: 3px;
    background: linear-gradient(90deg, transparent, var(--moads-muted-color));
    animation: animate1 2s ease-in-out infinite;
}

.button-2 span:nth-child(2) {
    top: -100%;
    right: 0;
    width: 3px;
    height: 100%;
    background: linear-gradient(180deg, transparent, var(--moads-muted-color));
    animation: animate2 2s ease-in-out infinite;
    animation-delay: 0.3s;
}

.button-2 span:nth-child(3) {
    bottom: 0;
    right: 0;
    width: 100%;
    height: 3px;
    background: linear-gradient(270deg, transparent, var(--moads-muted-color));
    animation: animate3 2s ease-in-out infinite;
    animation-delay: 0.6s;
}

.button-2 span:nth-child(4) {
    bottom: -100%;
    left: 0;
    width: 3px;
    height: 100%;
    background: linear-gradient(360deg, transparent, var(--moads-muted-color));
    animation: animate4 2s ease-in-out infinite;
    animation-delay: 0.9s;
}

.button-2.primary,
.button-2.secondary {
    span:nth-child(1) {
        background: linear-gradient(90deg, transparent, #fff);
    }
    span:nth-child(2) {
        background: linear-gradient(180deg, transparent, #fff);
    }
    span:nth-child(3) {
        background: linear-gradient(270deg, transparent, #fff);
    }
    span:nth-child(4) {
        background: linear-gradient(360deg, transparent, #fff);
    }
}

.button-2 {
    &.primary {
        span {
            opacity: 0.4;
        }
    }
    &.secondary {
        span {
            opacity: 0.2;
        }
    }
}

@keyframes animate1 {
    0% {
        left: -100%;
    }
    50%,
    100% {
        left: 100%;
    }
}

@keyframes animate2 {
    0% {
        top: -100%;
    }
    50%,
    100% {
        top: 100%;
    }
}

@keyframes animate3 {
    0% {
        right: -100%;
    }
    50%,
    100% {
        right: 100%;
    }
}

@keyframes animate4 {
    0% {
        bottom: -100%;
    }
    50%,
    100% {
        bottom: 100%;
    }
}

span.glow-bg {
    &::before {
        position: absolute;
        top: -1%;
        left: -1%;
        width: 101%;
        height: 101%;
        content: '';
        background-color: #3a4dd6;
        background-image: linear-gradient(120deg, #fb8c15 0%, #fda73a 44%, #3a4dd6 55%, #4d69e2 100%);
        filter: blur(15px);
        z-index: -1;
    }
}

svg.filters {
    height: 0;
    width: 0;
    position: absolute;
    z-index: -1;
}
.glow-filter {
    position: relative;
    display: inline-block;
    scale: 1;

    animation: onloadscale 1s ease-out forwards;
}
.glow-filter::before {
    content: attr(data-text);
    position: absolute;
    pointer-events: none;
    color: #fffaf6;
    background: linear-gradient(0deg, #dfe5ee 0%, #fffaf6 50%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    filter: url(#glow-4);
    -moz-filter: url(#glow-4);
    -webkit-filter: url(#glow-4);

    opacity: 0;
    animation: onloadopacity 1s ease-out forwards;
}
// .glow-text {
//     color: var(--moads-h1-color);
//     -webkit-text-fill-color: inherit;
//     background-clip: unset;
//     text-shadow:
//         0 0 1vw #fb8c1580,
//         0 0 2vw #fb8c1580,
//         0 0 2.5vw #fb8c1580,
//         0 0 3vw #fb8c1580,
//         0 0 4vw #fb8c1580;
// }
@keyframes onloadscale {
    24% {
        scale: 1;
    }
    100% {
        scale: 1.02;
    }
}
@keyframes onloadopacity {
    24% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.d-flex {
    display: flex;
    --moads-col-gap: 2rem;
    gap: var(--moads-col-gap);
    // justify-content: center;
    // align-items: center;
}

.jc-between {
    justify-content: space-between;
}

.col-1 {
    flex: 0 0 auto;
    width: calc(8.33333333% - (var(--moads-col-gap) / 2));
}

.col-2 {
    flex: 0 0 auto;
    width: calc(16.66666666% - (var(--moads-col-gap) / 2));
}

.col-3 {
    flex: 0 0 auto;
    width: calc(25% - (var(--moads-col-gap) / 2));
}

.col-4 {
    flex: 0 0 auto;
    width: calc(33.33333333% - (var(--moads-col-gap) / 2));
}

.col-5 {
    flex: 0 0 auto;
    width: calc(41.66666666% - (var(--moads-col-gap) / 2));
}

.col-6 {
    flex: 1;
}

.col-7 {
    flex: 0 0 auto;
    width: calc(58.33333333% - (var(--moads-col-gap) / 2));
}

.col-8 {
    flex: 0 0 auto;
    width: calc(66.66666666% - (var(--moads-col-gap) / 2));
}

.col-9 {
    flex: 0 0 auto;
    width: calc(75% - (var(--moads-col-gap) / 2));
}

.col-10 {
    flex: 0 0 auto;
    width: calc(83.33333333% - (var(--moads-col-gap) / 2));
}

.col-11 {
    flex: 0 0 auto;
    width: calc(91.66666666% - (var(--moads-col-gap) / 2));
}

.col-12 {
    flex: 0 0 auto;
    width: calc(100% - (var(--moads-col-gap) / 2));
}

.m-0 {
    margin: 0;
}

.muted {
    color: var(--moads-muted-color);
}

.subtitle {
    font-family: var(--moads-font-family-heading);
    font-size: 0.75rem;
    font-weight: 580;
    letter-spacing: 4px;
    text-transform: uppercase;
    display: block;
    color: var(--moads-muted-color);
    margin-bottom: 0.75rem;
}

.banner {
    padding-block-start: 3rem;
    background: url(assets/img/hero/autohandler-hero-bg-dark.webp);
    background-size: cover;
    background-position: center;
    margin-bottom: 0;

    &.no-bg {
        background: none;
    }

    &.no-p {
        padding: 0;
    }

    h1 {
        background-image: linear-gradient(2005deg, #fff 8.55094%, #8792a4 107.012%);
        background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    h2 {
        margin-bottom: 1rem;
    }

    // .gradient {
    //     background-color: #fb8c15;
    //     background-image: linear-gradient(
    //         90deg,
    //         #ffc837 0%,
    //         #fb8c15 42%,
    //         #8e54e9,
    //         79%,
    //         #4d69e2 100%
    //     );
    //     -webkit-background-clip: text;
    //     background-clip: text;
    //     color: transparent;
    //     display: inline-block;
    // }

    .gradient-bg {
        background-color: #8e47fe;
        background-image: linear-gradient(45deg, #ffc837 0%, #fb8c15 33%, #8e54e9 66%, #4d69e2 100%);
        border-radius: 24px;

        &.primary {
            background-color: #fb8c15;
            background-image: linear-gradient(to right, #fb8c15 0%, #ffc837 51%, #fb8c15 100%);
        }

        &.secondary {
            background-color: #4d69e2;
            background-image: linear-gradient(to right, #4d69e2 0%, #8e54e9 51%, #4d69e2 100%);
        }

        .gradient-bg__content {
            overflow: hidden;
            position: relative;
            padding: 56px;

            h4 {
                color: #fff;
                text-align: center;
            }
            p {
                color: #f0f1f3;
                text-align: center;
            }
        }
    }

    .gradient-border {
        position: relative;
        padding: 1px;
        overflow: hidden;
        border-radius: 24px;
        margin: 0 auto;

        &::before {
            position: absolute;
            top: -10%;
            left: -10%;
            width: 120%;
            height: 120%;
            content: '';
            filter: blur(20px);
        }

        &.autohandler {
            &::before {
                background-color: #fb8c15;
                background-image: linear-gradient(to right, #fb8c15 0%, #fda73a 51%, #fb8c15 100%);
            }
        }

        &.publisher {
            &::before {
                background-color: #4d69e2;
                background-image: linear-gradient(to right, #4d69e2 0%, #7090ea 51%, #4d69e2 100%);
            }
        }

        .gradient {
            -webkit-background-clip: text;
            background-clip: text;
            color: transparent;
            display: inline-block;
        }

        &.primary {
            .gradient,
            &::before {
                background-color: #fb8c15;
                background-image: linear-gradient(to right, #fb8c15 0%, #ffc837 51%, #fb8c15 100%);
            }
        }

        &.secondary {
            .gradient,
            &::before {
                background-color: #4d69e2;
                background-image: linear-gradient(to right, #4d69e2 0%, #8e54e9 51%, #4d69e2 100%);
            }
        }
    }

    .gradient-border__content {
        overflow: hidden;
        position: relative;
        padding: 2rem;
        border-radius: 24px;
        background: var(--moads-card-background-color);
    }

    .buttons {
        margin-block: 0;
        justify-content: flex-start;
        gap: 1rem;
    }
}

.info-box,
.quote.ceo {
    background: url(assets/img/hero/autohandler-hero-bg-dark.webp);
    background-size: cover;
    background-position: center;
}

.step-box {
    background: var(--moads-card-overlay-bg-color);
    padding: 32px;
    border-radius: 16px;
}

.step-box__header {
    .step-box__icon {
        display: flex;
        align-items: center;
        gap: 1rem;
        margin: 1rem 0;

        .icon {
            width: 60px;
            height: 60px;
            border-radius: 50%;
            border: 2px solid var(--moads-muted-border-color);
            background-color: var(--moads-card-background-color);
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                width: 24px;
                height: auto;
                filter: var(--moads-svg-filter);
            }
        }

        h6 {
            margin: 0;
            color: var(--moads-muted-color);
        }
    }
}

.step-box__content {
    display: flex;
    flex-direction: column;

    p {
        font-size: 18px;
        color: var(--moads-muted-color);
        margin-bottom: 0;
    }

    .emphasized {
        font-size: 1rem;
        margin-top: 0;
        line-height: 1.14;
    }
}

.faq-list {
    padding-left: 0;

    h6 {
        color: var(--moads-muted-color);
    }

    li {
        cursor: pointer;
        position: relative;
        list-style: none;
        border-bottom: 1px solid var(--moads-muted-border-color);
        transition: 0.3s;

        &::after {
            content: '';
            border: 3px solid var(--moads-muted-border-color);
            height: 0;
            transition: 0.3s transform;
            transform: scaleY(0);
            transform-origin: 50% 100%;
            width: 100%;
            position: absolute;
            bottom: 0;
            left: 0;
        }

        &:hover {
            &::after {
                transform: scaleY(0.35);
            }
        }

        &.expanded {
            .answer {
                display: block;
            }
            .icon {
                &::after {
                    content: '-';
                    transform: rotateZ(180deg);
                }
            }
        }

        .question {
            display: block;
            color: var(--moads-h1-color);
            font-weight: 600;
            padding: 1rem 2.25rem 1rem 0;
        }

        .answer {
            display: none;
            color: var(--moads-muted-color);
            padding-bottom: 1rem;
        }

        .icon {
            cursor: pointer;
            font-weight: 500;
            font-size: 32px;
            line-height: 24px;
            text-align: center;
            height: 24px;
            width: 24px;
            position: absolute;
            top: 24px;
            right: 12px;

            -webkit-text-fill-color: transparent;
            -webkit-box-decoration-break: clone;
            box-decoration-break: clone;

            &::after {
                display: block;
                background: var(--moads-muted-color);
                -webkit-background-clip: text;
                background-clip: text;
                transition:
                    transform 0.3s ease,
                    opacity 0.2s ease;
                content: '+';
                opacity: 0.4;
            }
        }
    }
}

.qa-list {
    margin-top: 0;
    padding-right: 40px;
    margin-bottom: 0;
    padding-inline-start: 60px;
    // padding-top: var(--moads-typography-spacing-vertical);
    // padding-bottom: var(--moads-typography-spacing-vertical);

    li {
        position: relative;
        list-style: none;
        margin-bottom: 0;
        padding-top: 0.15rem;
        padding-bottom: 2rem;

        &:last-child {
            padding-bottom: 0;
        }

        &::before {
            position: absolute;
            content: '';
            width: 8px;
            height: 8px;
            top: 8px;
            left: -60px;
            border-style: solid;
            border-width: 2px;
            border-color: rgb(100 107 121 / 23%);
            border-radius: 1000px;
        }

        &::after {
            position: absolute;
            content: '';
            width: 2px;
            height: calc(100% - 8px);
            top: 16px;
            left: -57px;
            border-style: solid;
            border-width: 1px;
            border-color: rgb(100 107 121 / 23%);
        }

        .question {
            display: block;
            color: var(--moads-h1-color);
            font-size: 1rem;
            font-family: var(--moads-font-family-heading);
            letter-spacing: 0.8px;
            font-weight: 580;
            margin-bottom: 0.5rem;
        }

        .answer {
            color: var(--moads-muted-color);
            display: block;
            font-size: 1rem;
        }
    }
}

app-bento.plain {
    margin: 1rem 0 2rem 0;
}

app-bento.plain app-bento-item {
    background: none;
    padding: 0;
    &::before {
        content: none;
    }
}

app-bento-item.quote {
    &::after {
        content: '';
        background-image: url("data:image/svg+xml;charset=UTF-8,%3c?xml version='1.0' encoding='UTF-8'?%3e%3csvg id='Layer_2' xmlns='http://www.w3.org/2000/svg' width='53.68' height='46.67' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 53.68 46.67'%3e%3cdefs%3e%3cstyle%3e.cls-1%7bfill:url(%23linear-gradient-2);%7d.cls-2%7bfill:url(%23linear-gradient);%7d%3c/style%3e%3clinearGradient id='linear-gradient' x1='-3.79' y1='3.79' x2='24.11' y2='31.68' gradientUnits='userSpaceOnUse'%3e%3cstop offset='.11' stop-color='%23ffc837'/%3e%3cstop offset='.37' stop-color='%23fb8c15'/%3e%3cstop offset='.67' stop-color='%238e54e9'/%3e%3cstop offset='.89' stop-color='%234d69e2'/%3e%3c/linearGradient%3e%3clinearGradient id='linear-gradient-2' x1='25.51' x2='53.41' xlink:href='%23linear-gradient'/%3e%3c/defs%3e%3cg id='objects'%3e%3cpath class='cls-2' d='M24.39,0v20.7c0,12.11-10.16,21.95-16.49,25.83-.8.49-1.67-.53-1.06-1.25,1.71-2,8.54-9.54,9.82-20.9H0V0h24.39Z'/%3e%3cpath class='cls-1' d='M53.68,0v20.7c0,12.11-10.16,21.95-16.49,25.83-.8.49-1.67-.53-1.06-1.25,1.71-2,8.54-9.54,9.82-20.9h-16.67V0h24.39Z'/%3e%3c/g%3e%3c/svg%3e");
        background-size: contain;
        background-repeat: no-repeat;
        width: 42px;
        height: 42px;
        position: absolute;
        bottom: 42px;
        right: 42px;
    }
}

.quote {
    padding-block-start: 0;

    &.customers {
        background-color: var(--moads-card-background-color);
        padding-block: 3rem;

        app-bento-item {
            padding: 36px;
        }
    }

    .quote__ceo {
        display: flex;
        gap: 2rem;

        // &::after {
        //     position: absolute;
        //     content: '"';
        //     font-family: var(--moads-font-family-heading);
        //     font-size: 64px;
        //     bottom: 80px;
        //     right: 40px;
        //     color: var(--moads-h1-color);
        // }

        .quote__ceo-img {
            flex: 0 0 150px;
            position: relative;
            width: 150px;
            height: 150px;

            .quote__ceo-img-overlay {
                position: relative;
                display: block;
            }

            &::before {
                position: absolute;
                top: -1%;
                left: -1%;
                width: 101%;
                height: 101%;
                content: '';
                background-color: #8e47fe;
                background-image: linear-gradient(120deg, #fb8c15 0%, #fda73a 44%, #7090ea 55%, #4d69e2 100%);
                filter: blur(15px);
                z-index: -1;
                border-radius: 50%;
            }

            img {
                width: 150px;
                height: auto;
                border-radius: 50%;
                overflow: hidden;
                object-fit: contain;
            }
        }

        .quote__ceo-content {
            display: flex;
            flex-direction: column;
            gap: 2rem;
        }

        .quote__ceo-author {
            span {
                font-family: var(--moads-font-family-heading);
                font-size: 1.25rem;
                color: var(--moads-h1-color);
                font-weight: 580;
                margin-bottom: 0;
                line-height: 1.14;
                display: inline-block;
            }
        }

        small {
            display: block;
        }

        p {
            margin-bottom: 0;
        }
    }

    .quote__customers {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: stretch;

        .quote__customers-img {
            display: flex;
            gap: 1rem;
            align-items: flex-start;
            margin-bottom: 2rem;

            p {
                font-weight: 600;
                margin-bottom: 4px;
                color: var(--moads-h1-color);
            }

            small {
                display: block;
                line-height: 1.4;
            }

            img {
                flex: 0 0 80px;
                height: auto;
                border-radius: 50%;
                overflow: hidden;
                object-fit: contain;
            }
        }

        .quote__customers-text {
            p {
                font-size: 0.9rem;
                margin-bottom: 0;
            }
        }
    }
}

.online-presence .d-flex {
    --moads-col-gap: 0.5rem;
}

.question-i,
.answer-i,
.info-i,
.muted-i {
    border: 1px dashed rgb(100 107 121 / 23%);
    padding: 0.5rem 0.75rem;
    border-radius: 0.25rem;
    color: var(--moads-del-color) !important;
    display: inline-block !important;
    margin-bottom: var(--moads-typography-spacing-vertical);

    b {
        color: var(--moads-del-color) !important;
    }

    &:before {
        background: url(assets/img/icons/question.svg);
        background-repeat: no-repeat;
        background-size: cover;
        content: '';
        display: inline-block;
        width: 1.25rem;
        height: 1.25rem;
        margin-right: 8px;
        margin-top: -4px;
        vertical-align: middle;
        opacity: 0.8;
    }
}

.question-i {
    color: var(--moads-del-color) !important;

    &:before {
        background-image: url(assets/img/icons/question.svg);
        filter: brightness(0) saturate(100%) invert(53%) sepia(3%) saturate(6202%) hue-rotate(314deg) brightness(110%)
            contrast(72%);
    }

    b {
        color: var(--moads-del-color) !important;
    }
}

.answer-i {
    color: var(--moads-ins-color) !important;

    &:before {
        background-image: url(assets/img/icons/answer.svg);
        filter: brightness(0) saturate(100%) invert(73%) sepia(3%) saturate(4342%) hue-rotate(113deg) brightness(86%)
            contrast(86%);
    }

    b {
        color: var(--moads-ins-color) !important;
    }
}

.info-i {
    color: var(--moads-att-color) !important;

    &:before {
        background-image: url(assets/img/icons/attention.svg);
        filter: brightness(0) saturate(100%) invert(69%) sepia(11%) saturate(1182%) hue-rotate(342deg) brightness(94%)
            contrast(87%);
    }

    b {
        color: var(--moads-att-color) !important;
    }
}

.muted-i {
    color: var(--moads-muted-color) !important;

    &:before {
        background-image: url(assets/img/icons/answer.svg);
        filter: brightness(0) saturate(100%) invert(73%) sepia(3%) saturate(4342%) hue-rotate(113deg) brightness(86%)
            contrast(86%);
    }

    b {
        color: var(--moads-muted-color) !important;
    }
}

[data-theme='dark'] {
    .only-on-dark {
        display: block;
    }

    .only-on-light {
        display: none;
    }
}

[data-theme='light'] {
    .only-on-dark {
        display: none;
    }

    .only-on-light {
        display: block;
    }

    .button-1 {
        color: #fff;
        background: #373c44;
    }
}

.speedometer__wrapper {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 10;
}

.speedometer__wrapper-v2 {
    display: flex;
    justify-content: center;
    position: relative;
    margin-top: 2rem;

    .speedometer__button-wrapper {
        display: flex;
        justify-content: center;
    }
    .speedometer {
        width: 160px;

        .speedometer__arrow {
            position: absolute;
            z-index: 3;
            width: 80px;
            bottom: 10px;
            left: 62px;
            transform-origin: 20px 60px;
            transform: rotate(-140deg);
        }
    }
}

.speedometer {
    position: relative;
    display: block;
    width: 160px;

    .speedometer__base {
        z-index: 2;
    }

    .speedometer__arrow {
        position: absolute;
        z-index: 3;
        width: 80px;
        bottom: 36px;
        left: 11%;
        transform-origin: 63px 40px;
    }
}

.img-glow {
    padding: 0;
    background: none;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    border-radius: 24px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: clamp(200px, 100%, 100%);

    &::after {
        content: '';
        width: 100%;
        height: 100%;
        top: 0;
        position: absolute;
        background: inherit;
        filter: blur(100px) blur(100px);
        z-index: -2;
    }
}

@media (min-width: 1024px) {
    .hide-on-desktop {
        display: none !important;
    }
}

@media (max-width: 1023px) {
    .hide-on-mobile {
        display: none !important;
    }
    section h2 {
        font-size: 2.15rem;
    }

    app-bento-item.quote {
        &::after {
            width: 32px;
            height: 32px;
            right: auto;
            bottom: 32px;
            left: 40px;
        }
    }
    .banner {
        .gradient-border {
            .gradient-border__content {
                padding: 1.5rem;
            }
        }
    }
    .d-flex {
        display: block;
    }

    .col-1,
    .col-2,
    .col-3,
    .col-4,
    .col-5,
    .col-6,
    .col-7,
    .col-8,
    .col-9,
    .col-10,
    .col-11,
    .col-12 {
        flex: 0 0 auto;
        width: 100%;
    }

    .button-1 {
        font-size: 16px;
    }

    .quote {
        .quote__customers {
            .quote__customers-img {
                img {
                    flex: 0 0 52px;
                }
            }
        }

        .quote__ceo {
            gap: 1rem;
            .quote__ceo-content {
                flex-direction: column-reverse;
                gap: 1rem;
            }
        }
    }

    .quote .quote__ceo .quote__ceo-img {
        flex: 0 0 52px;
        width: 52px;
        height: 52px;
        img {
            width: 50px;
        }
    }
}

@keyframes bento-fade {
    to {
        opacity: 1;
        filter: blur(0);
        transform: translateX(-0);
    }
}

/* Lenis */
html.lenis,
html.lenis body {
    height: auto;
}

.lenis.lenis-smooth {
    scroll-behavior: auto !important;
}

.lenis.lenis-smooth [data-lenis-prevent] {
    overscroll-behavior: contain;
}

.lenis.lenis-smooth iframe {
    pointer-events: none;
}
