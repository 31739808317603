.theme-toggle {
    transform: scale(1.125) translateY(-0.0625rem);
}

article {
    img {
        scale: 0.6;
        opacity: 0;
        animation: fade-in linear forwards;
        animation-timeline: view();
        animation-range: entry 200px;
    }
}

@keyframes fade-in {
    to {
        scale: 1;
        opacity: 1;
    }
}

.hidden {
    transition: none;
}
